.con {
  width: 100%;
  margin-top: 3rem;
  padding: 0 var(--side-gap);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.menuCon {
  /* display: flex; */
  width: max-content;
  margin-right: 2rem;
}

.menu {
  cursor: pointer;
  position: relative;
  height: 3rem;
  width: 6rem;
  border: 2px solid;
  border-radius: 1.5rem;
  border-color: transparent;
  background-color: transparent;
  text-align: center;
  line-height: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.menu:hover {
  color: #fff;
  background-color: var(--bg-gray3-color);
}

.menu.selected {
  color: #fff;
  border-color: #fff;
  background-color: var(--bg-gray3-color);
}

.close {
  position: absolute;
  cursor: pointer;
  user-select: none;
  width: 1rem;
  height: 1rem;
  color: var(--font-dark-color);
  right: 0.3rem;
  top: 0.9rem;
  text-align: center;
  line-height: 0.9rem;
  font-size: 1rem;
  backdrop-filter: blur(5px);
  color: #ffffff;
  transition: all 0.3s;
  opacity: 1;
  pointer-events: none;
}

.menu:hover .close {
  opacity: 1;
  pointer-events: auto;
}

.cardsCon {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: calc(1600px + 9rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.cardsCon::-webkit-scrollbar {
  display: none;
}

.cardsCon::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  border: 5px solid;
  border-color: #121317;
}

.card {
  position: relative;
  width: 390px;
  height: 290px;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.imageCard {
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
}

.imageCard :hover {
  transform: scale(1.05);
  transition: all 300ms;
  z-index: 100;
}

.more {
  width: 100%;
  cursor: pointer;
  height: 5rem;
  line-height: 5rem;
}

.selector {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.more:hover {
  background-color: #00000050;
}

.pageNext {
  cursor: pointer;
  padding: 0px 20px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin: 5px;
  height: 2rem;
  line-height: 2rem;
  border-radius: 3px;
  user-select: none;
}

.pageNext:hover {
  background-color: #00000050;
}

.pageNumber {
  cursor: pointer;
  padding: 0px 10px;
  margin: 5px;
  height: 2rem;
  line-height: 2rem;
  border-radius: 3px;
  user-select: none;
}
.pageNumber:hover {
  background-color: #00000050;
}

.coverImg2 {
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.coverImg2 img {
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.likeCon.like {
  background-color: #fa2d5380;
  color: #fa2d53;
}

.prompt {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  text-align: left;
  padding: 0 0.5rem;
  color: var(--font-dark-color);
  border-radius: var(--radius);
  background-color: #f8f8f880;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: hidden;
  height: 0;
  max-height: 5rem;
  overflow-y: overlay;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.prompt::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.prompt::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  border: none;
}

.prompt.show {
  height: fit-content;
  padding: 0.5rem;
}

.card:hover .prompt {
  opacity: 1;
}

.infoCon {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  padding: 0.5rem;
  color: var(--font-dark-color);
  border-radius: var(--radius);
  background-color: #f8f8f880;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--font-dark-color);
}

.infoCon.hide {
  opacity: 0;
}

.avatar,
.avatar img {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
}

.avatar {
  margin-right: 1rem;
}

.spaceholder {
  flex-shrink: 1;
  flex-grow: 1;
}

.skeleton {
  width: 390px;
  height: 290px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: var(--radius);
  background-color: var(--bg-gray3-color);
  overflow: hidden;
  font-size: 0.75rem;
  cursor: pointer;
  background: linear-gradient(90deg, #2f2f2f 25%, #232323 50%, #3d3d3d 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  padding: 2rem;
  border-radius: var(--radius);

  background-color: rgba(0, 0, 0, 0.5);
}

.emptyContainer:hover {
  background-color: var(--bg-gray4-color);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.emptyList {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  color: var(--font-light-color);
  animation: fadeIn 2s ease-in;
}

.dropdown {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 140px;
  padding: 5px;
  background-color: var(--bg-white-color);
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid white;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown:hover {
  background-color: var(--bg-gray4-color);
}

.dropdownContainer {
  position: relative;
  top: 0rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 1rem;
  padding: 2rem;
}

.filterSelect {
  background-color: #f5f5f5;
  color: #333;
}

.filterSelect {
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  appearance: none;
  font-size: 17px;
  color: white;
  cursor: pointer;
  text-align-last: center;
  outline: none;
}
.dropdownContainer option {
  background-color: #f5f5f5;
  color: #333;
}

.packing {
  filter: blur(4px);
}

.hideItem {
  display: none;
  opacity: 0;
}

.packingtext {
  position: absolute;
  top: 1rem;
  right: 8rem;
  width: 2rem;
  height: 2rem;
  font-size: 21px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusText {
  position: absolute;
  bottom: 2.5rem;
  left: 1rem;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusTextNoProgress {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadCon {
  position: absolute;
  top: 4rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: var(--radius);
  background-color: #ffffff80;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(69, 0, 217, 0.4);
}

.downloadCon:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.progressBar {
  width: 84%;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  height: 0.4rem;
  background-color: white;
  border-radius: 10px;
}

.progress {
  height: 100%;
}

.separator {
  /* 自定义分隔符的样式，例如： */
  border: 1px solid #ccc;
  width: 100%;
  margin: 20px 0;
}
