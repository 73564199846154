.con {
	width: 100%;
	margin: 2rem 0;
	padding: 0 var(--side-gap);
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--themeTextColor);
	font-size: 0.8rem;
	line-height: 1rem;
	z-index: 5000;
}
.mobileCon.con{
	padding: 0 2rem;
	margin-bottom: 1rem; 
}
.spaceholder {
	flex-grow: 1;
	flex-shrink: 1;
	margin: 0;
}

.con > div {
	margin-left: 2rem;
	cursor: pointer;
	border-bottom: 1px solid transparent;
	z-index: 1;
}

.con > div.nameCon {
	margin-left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.con img {
	margin-right: 0.5rem; 
	height: 1.5rem;
	width: 1.5rem;
	filter: var(--footerImgBrightness);
}

.con > div:hover {
	color: var(--theme-color);
	border-bottom: 1px solid var(--theme-color);
}
.con > div.nameCon:hover {
	color: var(--theme-color);
	border-bottom: 1px solid transparent;
}
.con > div.nameCon:hover img {
	filter: none;
}
.con a {
	color: inherit;
	text-decoration: none;
	font-size: inherit;
	line-height: inherit;
}
.redeem {
	color: #fa2d53;
}

.popupCon {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
}
.popup {
	position: absolute;
	top: 10vh;
	left: calc(50vw - 50vh);
	height: 80vh;
	width: 100vh;
	border-radius: var(--radius);
	background-color: var(--bg-gray4-color);
	overflow-y: auto;
	word-break: break-all;
	white-space: pre-wrap;
	padding: 1rem 0 1rem 1rem;
	border: none;
	outline: none;
}
