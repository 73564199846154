.panoramaGalleryWrapper{
  width: 100%;
	margin-top: var(--side-gap);
	padding: 0 var(--side-gap);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 10000;
}
.cardsCon {
	position: fixed;
	bottom: 2vw;
	left: 6vw;
	height: 112px;
	width: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #FFFFFF;
	border-radius:50%;
	transition: all 0.3s ease-in-out;
	opacity: 0;
	padding: 16px 16px 16px 24px;
}
@keyframes open {
	60%{
		width: 70%;
	}
	70%{	
		width: 95%;
	}
	80%{
		width: 88%;
	}
	90%{
		width: 90%;
	}
	100%{
		width: 88%;
	}
}
.cardsCon.showCardsCon {
		opacity: 1;
		border-radius: 16px;
		animation: open 0.5s ease-in forwards;
}
.cardsCon.hideCardsCon{
	animation: close 0.3s ease ;
}
@keyframes close {
	0%{
		width: 88%;
	}
	60%{
		width: 50%;
	}
	100%{
		width: 0%;
	}
}
.backBox{
	width: 60px;
	height: 88px;
	background: linear-gradient(180deg, #D7FF37 0%, #D7FF37 0%, #BAE800 100%);
	box-shadow: 0px 2px 8px 0px rgba(215,255,55,0.5);
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-shrink: 0;
	margin-right: 16px;
}
.backBox img{
	width: 32px;
	height: 32px;
}
.mainBox{
	display: flex;
}
.cards{
	flex-shrink: 0;
	margin-right: 12px;
	width: 160px;
	height: 80px;
	background: #D9D9D9;
	cursor: pointer;
}