.btnWrapper{
  position: absolute;
  right: 10vw;
  top: 4vh;
  height: 98px;
  background-color: #fff;
  cursor: pointer;
}
.btnTop{
  position: absolute;
  height: 56px;
  width: 56px;
  left: 0px;
   top: 0px;
  z-index: 2;
  border-radius: 50px;
  transition:all 0.3s ease;
  transform-origin: center center;
}
.btnDown{
  position: absolute;
  height: 56px;
  width: 56px;
  left: 0px;
  border-radius: 50px;
  transition:all 0.3s ease;
  transform-origin: center center;
    bottom: 0px;
    z-index: 1;

}
.btnTop:hover{
  transform: scale(1.1);
}
.btnDown:hover{
  transform: scale(1.1);
  z-index: 3;
   
}
.btnDownUnActive.btnDown:hover{
border: 1px solid #fff;
}
.iconInTop,.iconInDown{
  height: 28px;
  width: 28px;
  border-radius:6px 6px 0px 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.iconInTop img,.iconInDown img{
  width: 100%;
  height: 100%;
}
.btnTopUnActive{
 background: #4A00E0;
}
.btnDownUnActive{
  border: 1px solid #606060;
  background: #121317;
 
}
.btnTopActive{
  border: 1px solid #B996FF;
  background: #fff;
}

.btnDown.btnDownActive{
  background: #628EFF;
}