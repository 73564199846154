@font-face {
	font-family: 'CourierNewCustom';
	src: url('../../../assets/fonts/courbd.ttf') format('truetype');
}

.detailBox {
	position: relative;
	width: 50%;
	flex-shrink: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	user-select: none;
}

.toolbarBox {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	margin-bottom: 1rem;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
}

@media (max-height: 768px) {
	.hideOnSmallScreen {
		display: none !important;
	}
}

.creatorBox {
	display: flex;

}

.spaceholder {
	flex-grow: 1;
	flex-shrink: 1;
}

.iconWrapper {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-right: 1rem;
}

.avatar {
	height: 3rem;
	width: 3rem;
	border-radius: 1.5rem;
	overflow: hidden;
	margin-right: 1rem;
	margin-left: 0;
}

.avatar img {
	height: 3rem;
	width: 3rem;
}

.creatorInfoCon {
	text-align: left;
}

.creatorName {
	line-height: 1.8rem;
	font-size: 1.2rem;
	font-weight: bold;
	color: var(--font-dark-color);
}

.creatorInfo {
	line-height: 1.2rem;
	font-size: 1rem;
	color: var(--font-gray-color);
}

.titleBtn {
	width: 3rem;
	height: 3rem;
	border-radius: var(--radius);
	background-color: var(--bg-gray0-color);
	color: var(--bg-gray1-color);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	font-size: 1.2rem;
	margin-left: 1rem;
}

.titleBtnSuccess {
	width: 3rem;
	height: 3rem;
	border-radius: var(--radius);
	background-color: var(--bg-gray0-color);
	color: var(--bg-gray1-color);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	font-size: 1.2rem;
	margin-left: 1rem;
	background-color: rgb(70, 0, 217);
}

.titleBtn img {
	width: 1.2rem;
	height: 1.2rem;
}

.titleBtn.like {
	background-color: #fa2d5380;
	color: #fa2d53;
}

.unLike:hover {
	background-color: #ECECEC;
}

.titleBtn.download {
	background-color: var(--theme-color);
}

.titleBtn.downloadBtn:not(.download):hover {
	background-color: #ECECEC;
}

.titleBtn.shareCon {
	width: fit-content;
	padding: 0 1rem;
	/* color: var(--font-dark-color); */
	color: #333;
}

.titleBtn.shareCon.unshared:hover {
	background-color: #ECECEC;
}

.titleBtn.shareCon span {
	margin-right: 0.5rem;
}
.shared{
	background-color: rgba(0, 255, 0, 0.2);
}
.titleBtn {
	transition: all 0.3s ease;
}

.titleBtn:not(:last-of-type) {
	opacity: 0.7;
}

/* .modelWrapper{
	position: relative;
} */

.modelWrapper{
	flex-shrink: 1;
	flex-grow: 1;
	width: 100%;
	background: var(--bg-gray0-color);
	border-radius: var(--radius);
	overflow: hidden;
	border-radius: var(--radius);
	position: relative;
}

.modelView,.packBoxView {
height: 100%;
	width: 100%;
	background: var(--bg-gray0-color);
	border-radius: var(--radius);
	overflow: hidden;
	border-radius: var(--radius);
	position: relative;
}

.packBoxView{
		display: flex;
}

.progressInfo {
	width: 100%;
	margin-bottom: 0.5rem;
}

.progressTrack {
	width: 100%;
	height: 4px;
	border-radius: 2px;
	position: relative;
	background: var(--bg-light-color);
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	margin-bottom: 1rem;
}

.progressThumb {
	height: 4px;
	border-radius: 2px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--theme-color);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.progressAnimation {
	width: 30%;
	height: 4px;
	position: absolute;
	top: 0;
	left: 11rem;
	background: linear-gradient(90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(79, 0, 211, 0.8) 50%,
			rgba(255, 255, 255, 0) 100%);

	animation: move 4s linear infinite;
}

@keyframes move {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.modelPromptWrapper {
	background: var(--bg-light-color);
	border-radius: var(--radius);
	overflow: hidden;
	padding: 1rem 1rem 1rem 1rem;
	user-select: all;
}

.modelPrompt {
	overflow-y: auto;
	background: var(--bg-light-color);
	border-radius: var(--radius);
	max-height: 10vh;
	word-break: break-all;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: #000000aa;
}

.modelPrompt::-webkit-scrollbar-thumb {
	border: none;
	border-radius: 3px;
	border-color: var(--bg-gray0-color);
}

.modelPrompt::-webkit-scrollbar {
	width: 3px;
}

.modelInfoCon {
	padding: 10px;
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	right: 1rem;
	text-align: left;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.seedsWrapper {
	border-radius: var(--radius);
	overflow: hidden;
	top: 0.5rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border-radius: 5px;
	padding: 0.5rem 1rem;
}



.modelSeeds {
	overflow-y: hidden;
	border-radius: var(--radius);
	word-break: break-all;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 15px;
	color: rgba(0, 0, 0, 0.5);
}

.modelSeedsCon {
	z-index: 100;
	padding: 10px;
	position: absolute;
	top: 5rem;
	right: 1rem;
	text-align: left;
	margin-bottom: 1rem;
	width: auto;
	height: auto;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.licenseBox {
	position: absolute;
	/* width: 74px;
		height: 24px; */
	left: 1rem;
	top: 5.8rem;
}


.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.modelView {
	display: flex;
	width: 100%;
}

.packBox {
	flex-shrink: 0;
	flex-grow: 1;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background-color: #f2f2f2;
	padding: 20px;
	border-radius: 5px;
	transition: opacity 0.5s ease, transform 0.5s ease;
	position: relative;
	z-index: 2;
}
.assetsBox{
	margin-left: 11%;
		position: relative;
	top: -10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.assets{
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #DADADA;
position: relative;
	}
.assets::after{
	position: absolute;
	right: -16px;
	transform: translateY(-5%);
	top: 0;
	content: '';
	display: block;
	background-image: url('../../../assets/PackedIcon.png');
	width: 16px;
	height: 16px;
	background-size: 100%;
}
.assetsBox:hover .assets{
	color: #C3C3C3 ;
}
.assetsBox:hover .assets::after{
	filter: brightness(0.8);
}
.assetsBox:hover .assetsHoverInfo{
	opacity: 1;
}
.assetsHoverInfo{
		text-align: left;
		font-size: 12px;
		font-family: Arial-Regular, Arial;
		font-weight: 400;
		color: #C3C3C3;
		opacity: 0;
		transition:all 0.3s ease; 
}
.assetsHoverInfo a{
	color: #4A00E0;
	cursor: pointer;
}
.BundlePanelBox {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	flex-grow: 1;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	transition: opacity 0.5s ease, transform 0.5s ease;
	opacity: 0;
	transform: translateX(100%);
	z-index: 1;
}

.fadeOut {
	opacity: 0;
	z-index: 1;
	transform: translateX(-100%);
}

.fadeIn {
	opacity: 1;
	z-index: 2;
	transform: translateX(-100%);
}

.permissionDenied {
	margin-left: 11%;
	font-size: 24px;
	color: #000000;
	margin-bottom: 10px;
}

.purchaseInfo {
	margin-left: 11%;
	font-size: 16px;
	color: rgb(170, 170, 170);
	margin-top: 10px;
	margin-bottom: 15px;
	text-align: left;
}

.packWrapper {
	margin-left: 11%;
	margin-top: 15px;
	width: 158px;
	height: 64px;
	background: #FFFFFF;
	border-radius: 36px 36px 36px 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	/* padding: 0 10px 0 24px; */
	position: relative;
}

.packageButton {
	position: absolute;
	left: 24px;
	font-size: 16px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #212121;
	transition: all 0.2s ease;
	opacity: 1;
	visibility: visible;
}

.arrowWrapper {
	position: absolute;
	left: 100px;
	width: 44px;
	height: 44px;
	background: #4A00E0;
	border-radius: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease;
}

.arrowWrapper:hover {
	left: 0;
	width: 158px;
	height: 64px;
	border-radius: 36px;
}
.arrowIconBox{
	transition:all 0.3s ease;
	visibility: visible;
	opacity: 1;	
	display: flex;
	align-items: center;
	justify-content: center;
}
.arrowWrapper:hover .arrowIconBox{
	visibility: hidden;
	opacity: 0;
}
.arrowWrapper:hover +.packageButton{
	opacity: 0;
	visibility: hidden;
}
.arrowWrapper:hover  .packNow{
	opacity: 1;
	visibility: visible;
}
.packNow {
	width: 68px;
	font-size: 16px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #FFFFFF;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease;
	display: flex;
	flex-wrap: nowrap;
}
