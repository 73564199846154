.panoramaContainer {
  height: 100vh;
  overflow: hidden;
}

.panoramaWrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: calc(50vh - 16rem);
  transition: opacity 0.3s ease-in-out;
  user-select: none;
  top: 0;
}

.editorWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
}
.homeshow,.boadrshow{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.editorshow,.homeshow,.boadrshow{
  opacity: 1;
  visibility: visible;
  transition:all 0.5s ease;
}
.editorhidden,.homehidden,.boardhidden{
  opacity: 0;
  visibility: hidden;
}
.panoramaLandingPage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease;
}
.panoramaLandingPage.showPanoramaViewer{
  opacity: 1;
  visibility: visible;
}
.panoramaLandingPage.hidePanoramaViewer{
  opacity: 0;
  visibility: hidden;
}
.titleBox {
  cursor:hand;
  margin-top: 80px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  transform: translateY(-300%) scale(0.8);
  opacity: 0;
  transition: all 1s ease;
}

.tranform.titleBox {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.title {
  font-family: Courier New-Bold, Courier New;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  color: #ffffff;
}

.title:hover {
  color: #ffffff;
  transform: translateY(-5px);
  transition: color 0.3s, transform 0.3s;
}

.versionTitle {
  font-family: Arial-Regular, Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  color: white;
  letter-spacing: 0.05em;
}

.exploreBtn {
  margin-top: 56px;
  width: 138px;
  height: 55px;
  background: #d7ff37;
  border-radius: 122px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 55px;
  color: #373737;
  cursor: pointer;
  transform: translateY(500%);
  transition: all 1s ease;
}

.tranform.exploreBtn {
  transform: translateY(0);
}

.exploreBtn:hover {
  color: #d7ff37;
  background: rgba(215, 255, 55, 0.2);
  border: 1px solid #d7ff37;
}

.toolWrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2vw;
  display: flex;
  flex-direction: column;
  width: 626px;
  height: 144px;
  z-index: 1000;
  /* background-color: #fff; */
  overflow: hidden;
  padding: 0 10px;
  transition:opacity 0.3s,ease;
  opacity: 1;
}
.toolWrapper.disabledStyle{
  opacity: 0.7;
}

.toolWrapper.disabledStyle:hover *,
.toolWrapper.disabledStyle:hover *::before,
.toolWrappe.disabledStyler:hover *::after {
  cursor: not-allowed!important; 
}
.toolTransformBox {
  transition: transform 0.15s ease-out;
}

.toolBar {
  margin-top: 29px;
  display: flex;
}

.tool {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: grid;
  place-items: center;
  margin-right: 8px;
  background-color: #525252;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.tool.unActiveTool:hover .toolTip {
  opacity: 1;
  visibility: visible;
  z-index: 20;
}

.toolTip {
  position: absolute;
  top: -60%;
  left: 50%;
  transform: translateX(-50%);
  width: 41px;
  height: 15px;
  background: #525252;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolTip span {
  display: inline-block;
  transform: scale(0.5);
  text-align: center;
  transform-origin: center center;
  font-size: 20px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #c1c1c1;
  white-space: nowrap;
}

.toolBar .tool:nth-of-type(4),
.toolBar .tool:nth-of-type(6) {
  margin-right: 24px;
}

.tool svg {
  fill: #ababab;
  transition: all 0.3s ease;
}

.tool.unActiveTool:hover svg {
  fill: #f1f1f1 !important;
}

.brushController,
.eraserController {
  position: absolute;
  width: 143px;
  height: 4px;
  background-color: #fff;
  border-radius: 3px 3px 3px 3px;
  top: -18px;
  left: 0;
  transition: all 0.3s ease;
}
.thumb{
  position: absolute; 
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 12px;
  background: #D7FF37;
  border-radius: 2px;
  z-index: 10;
}
.fillBox{
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  background: #D7FF37;
  border-radius: 2px 0 0 2px;
}
.brushController.controllerHide,
.eraserController.controllerHide {
  opacity: 0;
  visibility: hidden;
}

.brushController.controllerShow,
.eraserController.controllerShow {
  opacity: 1;
  visibility: visible;
}

.depthTool {
  width: 102px;
  height: 32px;
  margin-left: 92px;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  border-radius: 8px;
  line-height: 32px;
  color: #ABABAB;
  background-color: #525252;
  transition: all 0.2s ease;
  cursor: pointer;
}
.depthTool.showDepth{
  color: #D7FF37;
  background: rgba(215, 255, 55, 0.20);
}

.gradientRadius{
  margin-top: 18px;
  width: 610px;
  height: 52px;
  padding: 2px;
  border-radius: 8px;
  display: inline-grid;
}

.generateInput {
  display: flex;
  width: 100%;
  height: 100%;
  background: #525252;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease;
}

.generateInput input {
  flex-grow: 1;
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 16px;
  color: #ffffffe3;
}
.prompt{
  border: 1px solid transparent;
}
.promptEmpty{
  animation: shake 0.15s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  border: 1px solid #FF7D00;
}

.promptEmpty input::placeholder{
  color: #FF7D00;
}
@keyframes shake {
  0%,
  100% {
    transform: translate(0,0);
  }
  50% {
    transform: translate(10px,-5px) ;
  }
}

.generateBtn {
  line-height: 48px;
  color: #d7ff37;
  width: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.generateAnimaBox{
  height: 100%;
  width: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.generateAnimaBox span{
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color:#d7ff37;
  animation-name:ballmove;
  animation-duration:1.2s;
  animation-timing-function:linear;
  animation-iteration-count:infinite;
}
.generateAnimaBox span:nth-of-type(2) {
   animation-delay:0.3s;
}
.generateAnimaBox span:nth-of-type(3) {
  animation-delay:0.6s;
}
.generateAnimaBox span:nth-of-type(4) {
  animation-delay:0.9s;
}
@keyframes ballmove {
    0%{
      transform: translateY(0);
    }
    12%{
      transform: translateY(-10px);
    }
    25%{
       transform: translateY(0);
    }
}


.styleKeyWordsBox {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 76px;
}

.styleKeyWords {
  cursor: pointer;
  margin-bottom: 12px;
  margin-right: 12px;
  flex-shrink: 0;
  border-radius: 31px;
  padding: 0 16px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #f0f0f0;
  border-radius: 30.894px;
  background: rgba(255, 255, 255, 0.20);
  transition: all 0.2s ease;
}
.styleKeyWords:hover{
  background: rgba(255, 255, 255, 0.35);
}
.styleKeyWords.selected{
 color: #D7FF37;
  background-color: rgba(215, 255, 55, 0.20);
}
.panoramaEditor {
  position: absolute;
  height: 100vh;
  width: 100vh;
  top: 0;
  left: 0;
}


.menuTips{
	position: fixed;
	bottom: 15vh;
	left: 5vw;
	width: 195px;
	height: 38px;
	padding: 12px 24px 12px 24px;
	border-radius: 40px;
	background: #FFFFFF;
	color: #373737;
	font-family: Arial;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: left;
	transition:all 0.5s ease;
}

@media (max-height:768px) {
  .smallScreen{ 
	  bottom: 22vh;
  }
}

.menuTipsShow.menuTips{
	opacity: 1;
	visibility: visible;
}
.menuTipsHide.menuTips{
	opacity: 0;
	visibility: hidden;
}
.menuTips::before{
	content: '';
	width: 10px;
	height: 10px;
	border-radius:20px;
	background-color: #FFFFFF;
	bottom: -60%;
	left: 15%;
	position: absolute;
}
.menuTips::after{
	content: '';
	width: 20px;
	height: 20px;
	border-radius:50%;
	background-color: #FFFFFF;
	bottom: -20%;
	left: 20%;
	position: absolute;
}

