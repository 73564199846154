@font-face {
  font-family: "Courier New Custom";
  src: url("../../assets/fonts/courbd.ttf") format("truetype");
}

.con {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: calc(50vh - 16rem);
  transition: opacity 0.3s ease-in-out;
}

.conBgImg img {
  position: absolute;
  top: calc(50vh - 30rem);
  left: 20vw;
  width: 50vw;
  z-index: -1;
}

.conRodinBgImg img {
  position: absolute;
  top: -38vw;
  left: 37vw;
  width: 90vw;
  z-index: -1;
  filter: blur(7px);
  animation-name: scaleIn;
  animation-duration: 1.2s;
}

@keyframes scaleIn {
  0% { 
    transform: scale(1.7);
    opacity: 0;}
  100% { 
    transform: scale(1);
    opacity: 100;}
}
.animated {
  transition: all 300ms;
}

.title {
  font-family: "Courier New Custom";
  font-size: 5rem;
  font-weight: bold;
  line-height: 4rem;
  color: white;
}

.title:hover {
  color: #ffffff;
  transform: translateY(-5px);
  transition: color 0.3s, transform 0.3s;
}

.versionTitle {
  font-family: "Courier New Custom";
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: white;
}

.titleBox{
 opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.transitioning{
  opacity: 0;
}

.iptConWithoutHover {
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 35rem;
  height: 4rem;
  border-radius: 2rem;
  background: var(--bg-light-color);
  
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iptCon {
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 35rem;
  height: 4rem;
  border-radius: 2rem;
  background: var(--bg-light-color);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.iptCon:hover {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  transform: translateY(-5px);
}
.iptCon img {
  position: static;
  height: 2rem;
  width: 2rem;
  z-index: 0;
  margin-right: 1rem;
  filter: brightness(0);
  cursor: pointer;
  opacity: 0.5;
}
.iptCon img:hover {
  filter: none;
  opacity: 1;
}
.ipt {
  outline: none;
  border: none;
  height: 100%;
  flex-grow: 1;
  border-radius: 2rem;
  padding: 0 2rem;
  font-size: 1rem;
  color: var(--font-dark-color);
  transition: box-shadow 0.3s, transform 0.3s;
}
.ipt::placeholder {
  font-size: 1rem;
}
.ipt:focus {
  box-shadow: 0 0 1px rgb(255, 255, 255);
  transform: translateY(0px);
}
.btnCon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  height: 3rem;
  width: 9rem;
  line-height: 1rem;
  border: 1px solid #8f8f8f;
  user-select: none;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btn:last-child {
  background: linear-gradient(#4a00e0, #870000);
  border: none;
  margin-left: 3rem;
}

.btn.disabled {
  cursor: not-allowed;
}
.btn:hover {
  color: #ffffff;
  transform: scale(1.1);
}

.animated-bg {
  animation: fadeAndMove 10s linear infinite;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.selected {
  color: #ffffff !important;
  transform: scale(1.3);
}

.textLine {
  color: #9c9c9c;
  transition: transform 0.3s, color 0.3s;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
  line-height: 3rem;
  font-size: 20px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  visibility: visible;
  min-width: 180px;
  min-height: 50px;
  text-align: center;
}

.textLine:hover:not(.selected) {
  color: #ffffff;
  transform: scale(1.1);
}

.menuWrapper {
  position: relative;
  display: flex;
  align-items: center;
}




.pageSelect {
  position: absolute;
  justify-content: center;
  margin-left: 4rem;
  left: calc(100% - 99%);
  top: 50%;
  transform: translateY(-50%);
}

.menuContainer {
  justify-content: center;
}

@media screen and (min-width: 1930px) {
  .pageSelect {
    left: calc(50% - 62.5rem);
  }
}

.uploadIcon {
  position: absolute;
  cursor: pointer;
  color: #4a00e0;
  left: 8%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  z-index: 1
  }

.inputSection {
  position: absolute;
  display: flex;
  align-items: center;
}

.inputText {
  position: absolute;
  font-size: 1rem;

  margin-left: 4.5rem;
  color: #464646;

  transition: color 0.3s linear, transform 0.3s linear;
}

.inputText:hover {
  color: #000;

  transform: scale(1.02);
}

.textSearchButton {
  background-color: #efe8ff;
  color: #4a00e0;
  height: 3.5rem;
  margin-right: 1rem;
  line-height: 3.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.rodinInput {
  background-color: transparent;
  border: none;
  color: #4a00e0;
  width: 85%;
  font-size: 15.5px;
  transition: width 0.5s ease-in-out, padding-left 0.5s ease-in-out,
    padding-right 0.5s ease-in-out, visibility 0.5s;
}
.rodinInput::placeholder {
  color: #bb99ff;
}

.rodinInput:focus {
  border: none;
  box-shadow: none;
}

.textSearchButton:hover {
  background-color: rgba(239, 232, 255, 0.8);
  transform: scale(1.02);
}

@keyframes fadeInHeight {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 30rem;
    opacity: 100;
  }
}

@keyframes fadeOutHeight {
  0% {
    height: 30rem;
    opacity: 100;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

.uploadPreviewActive {
  animation-name: fadeInHeight;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.uploadPreviewHide {
  animation-name: fadeOutHeight;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.uploadPreview {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  height: 30rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  overflow: hidden;
}


.imgContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  width: 460px;
  height: 250px;
  left: -22rem;
  top: 0.2rem;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}


.uploadPreviewTitle {
  position: absolute;
  font-size: 16px;
  top: 1.5rem;
  left: 2rem;
  font-family: Arial-Regular, Arial;
  color: #464646;
}

.switch {
  position: absolute;
  top: 1.6rem;
  right: 2.5rem;
  height: 20px;
}

.switch label {
  color: #464646;
  margin-left: 2px;
  font-size: 15px;
}

.slider.round {
  border-radius: 34px;
}

.uploadPreviewButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 1rem;
}

.reUploadButton {
  margin-bottom: 0.3rem;
  background-color: #efe8ff;
  width: 7rem;
  height: 2.8rem;
  line-height: 2.8rem;
  font-weight: bold;
  border-radius: 270px 270px 270px 270px;
  margin-left: 8rem;
  color: #4a00e0;
  z-index: 1;
}
.reUploadButton:hover {
  transform: scale(1.05);
}

.confirmButton {
  margin-bottom: 0.3rem;
  border: 1px solid #4a00e0;
  color: #fff;
  margin-right: 8rem;
  width: 7rem;
  height: 2.8rem;
  line-height: 2.8rem;
  font-weight: bold;
  background: #4a00e0;
  border-radius: 270px 270px 270px 270px;
  opacity: 1;
  z-index: 1;
}
.confirmButton:hover {
  transform: scale(1.05);
}

.dropOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 33rem;
  height: 5rem;
  background: rgb(230, 230, 230, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4rem;
  color: #464646;
  font-size: 4px;
  z-index: 1;
}

.overlayIcon {
  font-size: 1.5rem;
  color: #464646;
}

.rodinSearchIconOutline {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(184, 151, 252, 0.3);
  border-radius: 50%;
  margin-right: 0rem;
  cursor: pointer;
  width: 2.3em;
  height: 2.3em
}

.newProductHint {
  margin-top: 80px;
  position: absolute;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: #333;
  z-index: 1001;
}

.mtlUploadArea {
  display: inline;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-left: 0.5rem;
  height: 5rem;
  min-height: 5rem;
  border: 2px solid #4a00e0;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 13px;
  color: #4b00e09d; 
  cursor: pointer;
  overflow: hidden;
}

.fileIcon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

input#mtl-file-upload {
  display: none;
}

input#mtl-file-upload-text {
  display: none;
}

.mtlFileItem {
  position: relative;
  display: flex;
  gap: 0rem;
  align-items: center;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  padding-left: 0.4rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 14px;
  color: #4b00e09d;
  background: rgb(239, 232, 254)
}

.mtlDeleteBtn {
  right: 0.5rem;
  position: absolute;
  cursor: pointer;
  color: #4a00e09d;
}

.mtlFileList {
  overflow-y: auto;
  margin-bottom: 0.5rem;
}

::-webkit-scrollbar {
  display: none;
}

.textureWindow {
  position: fixed;
  left: calc(50% + 27rem);
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  height: 30rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  overflow: hidden;
}

.uploadIconTexture {
  color: #4a00e0;
  margin-left: 0rem;
  margin-top: 1rem;
  font-size: 1.5rem;
}


.fileIconList {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
}

.mtlFileItemList {
  position: relative;

  gap: 0rem;
  align-items: center;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  padding-left: 0.4rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 14px;
  color: #4b00e09d;
  cursor: pointer;
  border: 2px dashed #4a00e0;
  border-radius: 10px;
}



.mtlDeleteBtnList {
  right: 0.5rem;
  position: absolute;
  cursor: pointer;
  color: #4a00e09d;
}

.mtlFileListList {
  overflow-y: auto;
  margin-bottom: 0.5rem;
}

.mtlTitle {
  position: relative;
  align-items: center;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  padding-left: 0.4rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 14px;
  color: #4b00e09d;

}

.hintBar {
  position: absolute;
  width: 1.8rem;
  height: 10px;
  line-height: 10px;
  border-radius: 5px;
  font-size: 7px;
  color: #fff;
  top: 0.2rem;
  left: 0.2rem;
}

.lastSearch {
  position: fixed;
  right: 2.5%;
  bottom: 10%;
  background: rgb(73, 0, 220);
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  z-index: 1;
  transition: all 200ms;
}

.lastSearch:hover {
  transform: scale(1.1);
}

.hover {
  border: 2px inset #4b00e09d;
}
