
.PanoramaBoardWrapper{
  width: 612px;
  height: 112px;
  border-radius: 8px;
  background: #FFF;
  position: fixed;
  bottom: 35px;
  padding: 12px 20px;
  transition: all 0.5s ease;
}
.clickShow.PanoramaBoardWrapper{
  opacity: 1;
  visibility: visible;
}
.clickHide.PanoramaBoardWrapper{
  opacity: 0;
  visibility: hidden;
}
.spaceholder {
	flex-grow: 1;
	flex-shrink: 1;
}
.iconWrapper{
  display: flex;
}

.regenerateBtn{
  width: 87px;
  height: 32px;
  padding: 9px 12px;
  border-radius: 4px;
  background: #D7FF37;
  color: #373737;
  font-size: 12px;
  font-family: Arial;
  margin-right: 8px;
  cursor: pointer;
}
.regenerateBtn:hover{
  background: rgba(215, 255, 55, 0.60);
}
.remixBtn{
  width: 84px;
  height: 32px;
  padding: 9px 12px;
  border-radius: 4px;
  background: rgba(255, 135, 24, 0.10);
  color: #FF8718;;
  font-size: 12px;
  font-family: Arial;
  margin-right: 8px;
  cursor: pointer;
}

.addBtn{
  width: 46px;
  height: 32px;
  padding: 9px 12px;
  border-radius: 4px;
  background: rgba(255, 135, 24, 0.10);
  color: #FF8718;;
  font-size: 12px;
  font-family: Arial;
  cursor: pointer;
}
.remixBtn:hover,.addBtn:hover{
  background: rgba(255, 135, 24, 0.20);
}
.shareCon{
  width: 75px;
  height: 32px;
  display: flex;
  align-items: center;
  color: #2D2D2D;
  font-size: 12px;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  padding: 9px 12px;
  border-radius: 8px;
  background: #F8F8F8;
  background: transparent;
  cursor: pointer;
}
.iconBtn{
  border-radius: 8px;
  background: #f8f8f8;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 8px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}
.iconBtn.canHover:hover,.shareCon.canHover:hover{
  background-color: #ECECEC!important ;
}
.downloadProgress{
  background-color: rgba(70, 0, 217,0.15);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.info{
  margin-top: 16px;
  color: #B4B4B4;
  font-size: 14px;
  font-family: Arial;
  line-height: 16px;
  text-align: left;
}
.clickBtn{
  cursor: pointer;
  position: fixed;
  left: 50%;
  transform: translate(-50%,5%);
  width: 50vw;
  height: 10vw;
  flex-shrink: 0;
  border-radius:60%;
  background: rgba(26, 26, 26, 0.40);
  clip-path: inset(0 0 6vw 0);
  transition:all 0.3s ease;
}
.clickBtn.clickbtnShow{
  bottom: -6vw;
}
.clickBtn.clickbtnHide{
   bottom: -10vw;
}
.clickBtn div{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15%;
  color: #FFF;
  font-size: 16px;
  font-family: Arial;
}
.arrow{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  background-color: #fff;
  top: 1px;
  margin-right: 6px;
}
.arrow::after{
  position: absolute;
  left: 50%;
  top: 50%;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 1px;
  border-right: 2px solid #3c3c3c;
  border-top: 2px solid #3c3c3c;
  transform: translate(-50%,-25%) rotate(-45deg);
}