.con {
	width: 100vw;
	padding: 0 var(--side-gap);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	position: absolute;
	z-index: 10;
	opacity: 1;
	visibility: visible;
	transition:all 0.3s ease;
	z-index: 100;
}
.conHide.con{
	opacity: 0;
	visibility: hidden;
}
.spaceholder {
	flex-grow: 1;
	flex-shrink: 1;
}

.header {
	width: 100%;
	border-bottom: 1px solid var(--headBoderColor);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
}

.logoCon {
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	color:var(--headTextColor);
}

.logo {
	height: 3rem;
	margin-right: 1rem;
	transition: transform 0.5s;
	filter: var(--logoFilter);
}

.logoCon:hover .logo {
	transform: scale(1.05);
}

.logoCon>div {
	line-height: 2rem;
	font-size: 2rem;
	margin-top: 5px;
	font-weight: bold;
	transition: color 0.5s;
}

.logoCon:hover>div {
	color: var(--headTextHoverColor);
}

.balance {
	height: 2.5rem;
	border-radius: 1.25rem;
	background-color: var(--balanceBg);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1.5rem;
	user-select: none;
	cursor: pointer;
	color:var(--balanceColor);
}

.balance>img {
	width: 2rem;
	height: 2rem;
	margin-left: .25rem;
}

.balance>div {
	margin-left: 1rem;
	margin-right: 1rem;
}

.avatar {
	user-select: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	line-height: 1.5rem;
	color: var(--headAvatarColor);
}

.avatar img {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
}

.avatar div {
	display: flex;
	align-items: center;
	line-height: 1.5rem;
	margin-right: 10px;
}

.avatar .text {
	line-height: 5rem;
}

.avatarContainer {
	position: relative;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
}

.progressBarContainer {
	position: relative;
	height: 5px;
	background-color: #f0f0f0;
	border-radius: 3px;
	overflow: hidden;
	margin-bottom: 10px;
}

.progressBar {
	position: absolute;
	height: 100%;
	background-color: #4caf50;
	transition: width 0.5s ease-out;
}

.countdownText {
	font-size: 14px;
	color: #666;
	margin-bottom: 10px;
}

.avatarContainer {
	position: relative;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
}

