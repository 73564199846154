@font-face {
	font-family: 'CourierNewCustom';
	src: url('../../../assets/fonts/courbd.ttf') format('truetype');
}

.downloadCon {
	position: absolute;
	z-index: 10000;
	top: 4rem;
	right: 4rem;
	border-radius: var(--radius);
	box-shadow: 0 2px 8px 1px rgba(138, 138, 138, 0.16);
	width: 16rem;
	height: fit-content;
	padding: 1rem;
	background-color: #fff;
	color: var(--font-gray-color);
	user-select: none;
}
.loading{
	height:298px;
	width: 224px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.downloadTitle {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.downloadTitle div:last-child {
	cursor: pointer;
	user-select: none;
}

.row {
	position: relative;
	width: 100%;
	height: 5rem;
	border: 2px solid #fff;
	border-radius: var(--radius);
	box-shadow: 0 3px 6px 1px rgba(109, 38, 255, 0.12);
	background-color: var(--bg-gray0-color);
	color: var(--font-dark-color);
	margin-top: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.downloadCon .row.disabled {
	cursor: not-allowed;
	color: var(--font-gray-color);
}

.row.disabled:hover {
	border-color: #fff;
}

.row:hover {
	border-color: var(--theme-color);
}

.downloadCon .row.selected {
	color: var(--theme-color);
	background-color: var(--bg-theme-color);
	border-color: var(--theme-color);
}

.priceCon {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 0.75rem;
	line-height: 0.75rem;
}

.priceCon .icon {
	width: 1rem;
	height: 1rem;
	background-color: var(--theme-color);
	color: #fff;
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.priceCon .price {
	padding: 2px 0.5rem 2px 1rem;
	background-color: #ece2ff;
	margin-left: -0.5rem;
	color: var(--theme-color);
	border-radius: 5px;
}

.corner {
	font-size: 0.75rem;
	position: absolute;
	top: -5px;
	right: -5px;
	padding: 0.5rem;
	border-bottom-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	color: #fff;
	background-color: #e2d4ff;
}

.corner.sec {
	background-color: #ffd13c;
}

.downloadBtn {
	color: #fff;
	background-color: var(--theme-color);
	margin-top: 1rem;
	width: 100%;
	height: 3rem;
	border-radius: var(--radius);
	line-height: 1rem;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--theme-color);
	cursor: pointer;
}

.downloadBtn:hover {
	background-color: #fff;
	color: var(--theme-color);
}

.bodyOptCon.hide {
	overflow: hidden;
	height: 0;
}

.bodyOptCon {
	transition: height 0.3s ease;
	height: 10.5rem;
	width: 100%;
}

.subRow {
	width: 100%;
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.genderBtn {
	flex-grow: 1;
	flex-shrink: 1;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	background-color: var(--bg-gray1-color);
	border-left: none;
	border-right: none;
	color: var(--font-dark-color);
	user-select: none;
	cursor: pointer;
}

.subRow .genderBtn.selected {
	background-color: var(--theme-color);
	color: #fff;
}

.genderBtn:hover {
	background-color: var(--bg-gray0-color);
}

.genderBtn:first-child {
	border-top-left-radius: var(--radius);
	border-bottom-left-radius: var(--radius);
}

.genderBtn:last-child {
	border-top-right-radius: var(--radius);
	border-bottom-right-radius: var(--radius);
}

.subRow .label {
	margin-right: 1rem;
	width: 3rem;
}

.subRow .inputCon {
	position: relative;
	flex-grow: 1;
	flex-shrink: 1;
	height: 2rem;
	font-size: 1rem;
	color: var(--font-dark-color);
	border-radius: var(--radius);
	border-right: none;
	padding: 0 0.5rem;
	background-color: var(--bg-gray0-color);
	display: flex;
	justify-content: center;
	align-items: center;
}

.subRow .inputCon:focus-within {
	background-color: var(--bg-gray1-color);
}

.subRow input {
	color: var(--font-dark-color);
	border: none;
	outline: none;
	height: 100%;
	width: 3.5rem;
	flex-grow: 1;
	flex-shrink: 1;
	background-color: transparent;
}

.subRow .suffixHeight {
	margin-left: 1.2rem;
}

.subRow .suffixWeight {
	margin-left: 1.2rem;
}

.slider {
	-webkit-appearance: none;
	border-radius: 0.25rem;

}

.slider::before {
	content: "";
	position: absolute;
	left: 1rem;
	margin-top: 1rem;
	transform: translateY(-50%);
	width: 4rem;
	height: 3px;
	border-radius: 10px;
	background-color: rgb(81, 0, 222);
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 1rem;
	height: 1rem;
	background-color: rgb(81, 0, 222);
	color: rgb(81, 0, 222);
	border-radius: 50%;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	appearance: none;
	width: 1rem;
	height: 1rem;
	background-color: rgb(81, 0, 222);
	border-radius: 50%;
	cursor: pointer;
}

.progressCon {
	width: 100%;
	height: 8px;
	border-radius: 4px;
	margin-top: calc(4rem - 6px);
	overflow: hidden;
}

.progressBar {
	top: 0;
	left: 0;
	height: 8px;
	background-color: var(--theme-color);
}

.paidCon {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.paidIcon {
	width: 3rem;
	height: 3rem;
	border-radius: 1.5rem;
	background-color: #fff;
	line-height: 3rem;
	text-align: center;
	color: #fff;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
}

.costCon {
	font-size: 0.75rem;
}

.costCon span {
	color: var(--theme-color);
}

.paidTitle {
	color: var(--font-dark-color);
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
}

.paidSubtitle {
	font-size: 0.75rem;
	margin-bottom: 3rem;
}

.paidProgress {
	font-size: 0.75rem;
	width: 100%;
	text-align: left;
}

span.clickable {
	color: var(--theme-color);
	/* padding-bottom: 2px; */
	border-bottom: 1px solid var(--theme-color);
	cursor: pointer;
	user-select: none;
}

span.clickable:hover {
	/* font-weight: bold; */
	border-width: 2px;
}



.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}