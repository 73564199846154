.user-popup {
  position: absolute;
  top: calc(100% + 0.625rem);
  right: 0;
  background-color: var(--topUpBg);
  border: none;
  border-radius: 1rem;
  padding: 0.625rem;
  box-shadow: 0 0.25rem 0.375rem var(--noResultBg);
  display: flex;
  flex-direction: column;
  gap: 0.0625rem;
  z-index: 2;
  min-width: 12.5rem;
  overflow: hidden;
  opacity: 0;
}

.user-popup button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 0.875rem;
  color: var(--noResultText);
  padding: 0.75rem 1rem;
  transition: background-color 0.3s;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
}

.user-popup button span {
  line-height: normal;
}

.user-popup button:hover {
  background-color: var(--userTitleBg);
  border-radius: 1rem;
}

.user-popup button:last-child {
  border-top: 0.0625rem solid var(--userTitleBg);
}
.user-info {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: var(--userTitleBg);
  border-radius: 0.3125rem 0.3125rem 0 0;
  border-radius: 1rem;
}

.user-info img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
}

.user-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.user-name {
  color: var(--noResultText);
  font-size: 1rem;
}

.user-popup.show {
  opacity: 1;
  transform: translateY(0);
}

.user-balance {
  color: var(--noResultText);
  font-size: 0.625rem;
  margin-top: 0.125rem;
}

.fade {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear, visibility 0.3s;
}

.fade.show {
  visibility: visible;
  opacity: 1;
}
