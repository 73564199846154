.con {
	width: 100%;
	margin-top: var(--side-gap);
	padding: 0 var(--side-gap);
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.cardsCon {
	flex-grow: 1;
	flex-shrink: 1;
	max-width: calc(1600px + 9rem);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s ease;

	/* Firefox */
}

.cardsCon::-webkit-scrollbar {
	display: none;
}

.cardsCon::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 8px;
	border: 5px solid;
	border-color: #121317;
}


.more {
	color:var(--moreColor);
	width: 100%;
	cursor: pointer;
	height: 5rem;
	line-height: 5rem;
}

.more:hover {
	background-color: var(--moreHoverBg);
}

.coverImg2 {
	transition: transform 0.5s ease-in-out;
	width: 100%;
	height: 100%;
}

.coverImg2 img {
	transition: transform 0.5s ease-in-out;
	width: 100%;
	height: 100%;
}
.privateBox {
	position: absolute;
	right: 6%;
	top: 0;
	transform: translate(-15%, 70%);
	width: 32px;
	height: 32px;
	border-radius: 8px 8px 8px 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 35px;
}

.privateBox img {
	width: 16px;
	height: 16px;
}
.card:hover .coverImg2 {
	transform: scale(1.1);
}

.likeCon {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 2rem;
	height: 2rem;
	border-radius: var(--radius);
	background-color: #ffffff80;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.likeCon:hover {
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

.likeCon.like {
	background-color: #fa2d5380;
	color: #fa2d53;
}

.prompt {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	right: 1rem;
	text-align: left;
	padding: 0 0.5rem;
	color: var(--font-dark-color);
	border-radius: var(--radius);
	background-color: #f8f8f880;
	word-break: break-all;
	white-space: pre-wrap;
	overflow: hidden;
	height: 0;
	max-height: 5rem;
	overflow-y: overlay;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.prompt::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.prompt::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 3px;
	border: none;
}

.prompt.show {
	height: fit-content;
	padding: 0.5rem;
}

.card:hover .prompt {
	opacity: 1;
}

.infoCon {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	right: 1rem;
	padding: 0.5rem;
	color: var(--font-dark-color);
	border-radius: var(--radius);
	background-color: #f8f8f880;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--font-dark-color);
}

.infoCon.hide {
	opacity: 0;
}

.avatar,
.avatar img {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
}

.avatar {
	margin-right: 1rem;
}

.spaceholder {
	flex-shrink: 1;
	flex-grow: 1;
}

.skeleton {
	width: 200px;
	height: 260px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	border-radius: var(--radius);
	background-color: var(--bg-gray3-color);
	overflow: hidden;
	font-size: 0.75rem;
	cursor: pointer;
	background:var(--cardSkeletonBg) ;
	background-size: 200% 100%;
	animation: loading 1.5s infinite;
}

@keyframes loading {
	0% {
		background-position: 200% 0;
	}

	100% {
		background-position: -200% 0;
	}
}

.emptyContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-bottom: 1rem;
	padding: 2rem;
	border-radius: var(--radius);

	background-color: var(--noResultBg);
}

.emptyContainer:hover {
	background-color: var(--noResultBg);
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.emptyList {
	font-size: 1rem;
	font-weight: bold;
	text-align: center;
	padding: 1rem;
	color: var(--noResultText);
	animation: fadeIn 1s ease-in;
}

.dropdownContainer {
	position: relative;
	top: 0rem;
	right: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-end;
	margin-bottom: 1rem;
	padding: 2rem;
	cursor: pointer;
	user-select: none;
}
.dropdown {
	position: absolute;
	top: 0;
	right: 0;
	height: 50px;
	width: 140px;
	padding: 5px;
	background-color: var(--bg-white-color);
	border-radius: 4px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	border: 1px solid white;
	border-radius: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dropdown:hover {
	background-color: var(--bg-gray4-color);
}
.select{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0 14px 0 16px;
}
.arrow{
	width: 8px;
	height: 8px;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
	transform: rotate(-45deg);
}
.options{
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(calc(100% + 8px));
  background: #FFFFFF;
  box-shadow: 0px 2px 8px 0px rgba(215,215,215,0.25);
  border-radius: 6px;
	visibility: hidden;
	opacity: 0;
	transition: 0.3s all ease;
	overflow: hidden;
	z-index: 1;
}
.options.showOptions{
	visibility: visible;
	opacity: 1;
}
.option{
	height: 32px;
	line-height: 32px;
	font-size: 14px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #808080;
}

.option:hover{
  background: #EEEEEE ;
	color:#9D9D9D ;
}



.packing {
	filter: blur(4px);
}

.hideItem {
	display: none;
	opacity: 0;
}

.packingtext {
	position: absolute;
	top: 1rem;
	right: 8rem;
	width: 2rem;
	height: 2rem;
	font-size: 21px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #ffffff;
	line-height: 0px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.statusText {
	position: absolute;
	bottom: 2.5rem;
	left: 1rem;
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #ffffff;
	line-height: 0px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.statusTextNoProgress {
	position: absolute;
	bottom: 1.5rem;
	left: 1rem;
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #ffffff;
	line-height: 0px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.downloadCon {
	position: absolute;
	top: 4rem;
	right: 1rem;
	width: 2rem;
	height: 2rem;
	border-radius: var(--radius);
	background-color: #ffffff80;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(69, 0, 217, 0.4);
}

.downloadCon:hover {
	background-color: var(--noResultBg);
	cursor: pointer;
}
.progressBar {
	width: 84%;
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	height: 0.4rem;
	background-color: white;
	border-radius: 10px;
}

.progress {
	height: 100%;
}

.separator {
    /* 自定义分隔符的样式，例如： */
    border: 1px solid #ccc;
    width: 100%;
    margin: 20px 0;
}
