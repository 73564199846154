 .pagination {
   margin-top: 1rem;
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
 }

 .pageNext {
   cursor: pointer;
   padding: 0px 20px;
   margin-left: 1rem;
   margin-right: 1rem;
   margin: 5px;
   height: 2rem;
   line-height: 2rem;
   border-radius: 3px;
   user-select: none;
 }

 .pageNext:hover {
   background-color: #00000050;
 }

 .pageNumber {
   cursor: pointer;
   padding: 0px 10px;
   margin: 5px;
   height: 2rem; 
   line-height: 2rem;
   border-radius: 3px;
   user-select: none;
 }

 .pageNumber.activeBg{
  background-color:var(--pageNumberBg);
 }
 .pageNumber:not(.activeBg):hover {
   background-color: #00000050;
 }