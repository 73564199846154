.show.hide{
  visibility: visible;
  opacity: 1;
}

.hide{
 visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear, visibility 0.3s;
}

.loadingMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.loadingBox{
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 596px;
  height: 114px;
  background: var(--loadingBoxBg);
  border-radius: 16px;
  font-size: 16px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: var(--headTextHoverColor);
  display: flex;
  justify-content: center;
  align-items: center;
   visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear, visibility 0.3s;
}

.showLoading.loadingBox{
  visibility: visible;
  opacity: 1;
}
.loadingContainer{
  display: flex;
  justify-content: space-around;
}
.loading {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
}

.loading::before,
.loading::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  display: block;
}

.loading::before {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  background-image: linear-gradient(225deg, rgba(74,0,224,0.25) 0%, #4A00E0 100%);;
  animation: load012323 .5s infinite linear;
}

.loading::after {
  
  width: 85%;
  height: 85%;
  background: var(--loadingBoxBg);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes load012323 {
  to {
    transform: rotate(360deg);
  }
}