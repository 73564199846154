.tipsCon {
  position: fixed;
  z-index: 999;
  top: 5rem;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 100000;
}

.tip {
  max-width: 30rem;
  min-width: 15rem;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 3px;
  box-shadow: 0 0 10px #999;
  color: #ffffff;
  opacity: 0.99;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  transition: 0.3s ease;
  padding-right: 3rem;
  padding-left: 2rem;
}
.tip:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
}

.tip.error {
  background: rgb(189, 77, 69);
}
.tip.default {
  background: rgb(189, 77, 69);
}
.tip.warning {
  background: rgb(240, 173, 78);
}
.tip.success {
  background: rgb(92, 184, 92);
}
.tip.primary {
  background: rgb(2, 117, 216);
}

.row1 {
  margin-left: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row1 div:first-child {
  word-break: break-all;
  white-space: pre-wrap;
  text-align: start;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.close {
  font-weight: 700;
  color: #fff;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  position: absolute;
  top: 12px;
  right: 12px;
}
.notification-message {
  margin-left: 0.5rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
}

.close:hover {
  color: var(--theme-color);
}

.row2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.btn {
  height: 1.6rem;
  width: 5rem;
  font-size: 0.8rem;
  line-height: 1.6rem;
  text-align: center;
  border: 1px solid #fff;
  margin-left: 1rem;
  cursor: pointer;
  user-select: none;
  color: #fff;
  transition: background-color 0.1s ease, color 0.1s ease, font-weight 0.1s ease;
}
.btn.yes {
  color: #fff;
}
.btn:hover {
  font-weight: bold;
}

.icon {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
