
.privateBox {
	border-radius: 8px 8px 8px 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  position: relative;
	width:48px;
	height:48px
}
.privateBox:hover {
	cursor: pointer;
	background: rgba(236, 236, 236, 0.2)!important;
}

.privateBox img {
	max-width: 22px;
	max-height: 22px;
	width: 50%;
	height: 50%;
}

.privatehoverBox {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 12px 12px 12px 16px;
	border-radius: 23px;
	width: 291px;
	height: 47px;
	background: #fff;
	box-shadow: 0px 3px 6px 1px rgba(150,150,150,0.35);
  position: absolute;
	top: -15px;
	right: -50px;
	transform: translateY(-100%);
	color: #B0B0B0;
}


.privatehoverBox:before {
	content: "";
	position: absolute;
	bottom: 1px;
	right: 56px;
	width: 0;
	transform: translateY(100%);
	height: 0;
	border-top: 6px solid #fff;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	border-bottom: 10px solid transparent;
	/* box-shadow: 0px 5px 6px 0px rgba(197,197,197,0.25); */
}

.privateTips {
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	/* color: #2D2D2D; */
	margin-right: 24px;
}
.spaceholder{
	flex-grow: 1;
	flex-shrink: 1;
}
.privateCount {
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #B0B0B0;
}

.privateCount span {
	font-weight: bold;
	color: #4A00E0;
}

.show{
	opacity: 1;
	visibility: visible;
}
.hide{
	opacity: 0;
	visibility: hidden;
}
.bordtip{
	transition:all 0.3s ease;
	background: #ECECEC;
	border-radius: 4px;
	position: absolute;
	top: calc(-60%);
	left: 50%;
	width: 216px;
	height: 19px;
	transform: translateX(-50%);
}
.bordtip span{
	display: block;
	/* width: 100%; */
	transform-origin: center;
	line-height: 19px;
	/* transform: scale(0.5); */
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #404040 ;
}

.gallerytip{
	transition:all 0.3s ease;
	border-radius: 4px;
	position: absolute;
	top: calc(-90%);
	left: 50%;
	width: 216px;
	height: 19px;
	transform: translateX(-50%);
	background: var(--galleryPrivateTipBg);
	z-index: 1;
}

.gallerytip span{
		display: block;
	/* width: 100%; */
	transform-origin: center;
	line-height: 19px;
	/* transform: scale(0.5); */
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: var(--galleryPrivateColor) ;
}