.containerImage {
    width: 100%;
    height: 100%;
    left: 0rem;
    top: 0rem;
    max-width: 100%;
    max-height: 10rem;
  }
  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .loading-progress {
    height: 100%;
    background-color: #2196f3;
  }
  
  .loading-text {
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
  }
  