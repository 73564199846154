.NavWrapper {
  position: absolute;
  margin-left: 4rem;
  left: calc(100% - 99%);
  top: 25%;
  /* transform: translateY(-50%); */
  z-index: 100;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  opacity: 1;
  visibility: visible;
}
.conHide.NavWrapper{
	opacity: 0;
	visibility: hidden;
}
.mobileNavWrapper{
   position: absolute;
   top: 2rem;
    left: 0;
    z-index: 200;
    width: 100%;
    padding: 0 0 0 10%;
}

.NavBox {
  transition: transform 0.8s linear;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 100%;
}

.mobileNavBox{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
}

.normal {
  color: var(--navColor);
  cursor: pointer;
  user-select: none;
  /* margin-bottom: 10px; */
  font-size: 20px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  min-width: 180px;
  min-height: 50px;
  text-align: center;
  position: absolute;
  width: 180px;
}
.normal:first-of-type{
  left: 0;
  top: 0;
}
.normal:nth-of-type(2){
  left: 0;
  top: 60px;
}
.mobileNormal{
  color: var(--navColor);
  user-select: none;
  /* margin-bottom: 10px; */
  line-height: 3rem;
  font-size: 20px;
  text-align: center;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  flex: 1;  
}
.normal.canHover div{ 
  width: 100%;
  display: inline-block;
  transition: all 0.3s ease;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 180px;
}
.normal.canHover div:hover{
  color: var(--navHoverColor);
  transform: scale(1.1);
}
.beforeText{
  opacity: 1;
}
.afterText{ 
  opacity: 0;
}
.normal.canHover:hover .beforeText{
  opacity: 0;
}
.normal.canHover:hover .afterText{
  opacity: 1;
}

.selected div{
  color: var(--navHoverColor);
  transform: scale(1.3);
}