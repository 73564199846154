.likeCon {
	width: 2rem;
	height: 2rem;
	border-radius: var(--radius);
	background-color: #ffffff80;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
  margin-bottom: 1rem;
}

.likeCon:hover {
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

.likeCon.like {
	background-color: #fa2d5380;
	color: #fa2d53;
}