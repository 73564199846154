.card {
	position: relative;
	width: 200px;
	height: 260px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	border-radius: var(--radius);
	background-color: var(--cardBg);
	overflow: hidden;
	font-size: 0.75rem;
	cursor: pointer;
}

.card:hover .coverImg2 {
	transform: scale(1.1);
}

.card:hover .prompt {
	opacity: 1;
}

.packing {
	filter: blur(4px);
}

.coverImg2 {
	transition: transform 0.5s ease-in-out;
	width: 100%;
	height: 100%;
	position: relative;
}

.coverImg2 img {
	transition: transform 0.5s ease-in-out;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.coverImg2 .licenseBig {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 40px;
	height: 40px;
	clip-path: inset(20% 0px 0px 0px);
	transform: translate(20%,-20%);
}

.hoverLicense {
	width: 80px;
	height: 24px;
	background: rgba(73, 73, 73, 0.4);
	box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.25);
	border-radius: 8px 0px 8px 0px;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
}

.licenseSmall {
	display: inline-block;
	width: 16px;
	height: 16px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.hoverLicense div {
	display: flex;
	align-items: center;
	justify-content: center;
	display: inline-block;
	font-size: 12px;
	font-family: Arial-Bold, Arial;
	font-weight: bold;
	background: linear-gradient(180deg, #AB7FFD 0%, #F87AFF 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.packingtext {
	position: absolute;
	top: 1rem;
	right: 8rem;
	width: 2rem;
	height: 2rem;
	font-size: 21px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #ffffff;
	line-height: 0px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.progressBar {
	width: 84%;
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	height: 0.4rem;
	background-color: white;
	border-radius: 10px;
}

.progress {
	height: 100%;
}

.statusText {
	position: absolute;
	bottom: 2.5rem;
	left: 1rem;
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #ffffff;
	line-height: 0px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.statusTextNoProgress {
	position: absolute;
	bottom: 1.5rem;
	left: 1rem;
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #ffffff;
	line-height: 0px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toolBarBox {
	position: absolute;
	top: 1.2rem;
	right: 1rem;
	display: flex;
	flex-direction: column;
}

.likeCon {
	width: 2rem;
	height: 2rem;
	border-radius: var(--radius);
	background-color: #ffffff80;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
}

.likeCon:hover {
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

.likeCon.like {
	background-color: #fa2d5380;
	color: #fa2d53;
}

.downloadCon {
	margin-bottom: 1rem;
	width: 2rem;
	height: 2rem;
	border-radius: var(--radius);
	background-color: #ffffff80;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(69, 0, 217, 0.4);
}


.infoCon {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	right: 1rem;
	padding: 0.5rem;
	color: var(--font-dark-color);
	border-radius: var(--radius);
	background-color: #f8f8f880;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--font-dark-color);
}

.infoCon.hide {
	opacity: 0;
}
.card .privateBox{
	border-radius: 8px;
}

.avatar,
.avatar img {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
}

.avatar {
	margin-right: 1rem;
}

.spaceholder {
	flex-shrink: 1;
	flex-grow: 1;
}

.prompt {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	right: 1rem;
	text-align: left;
	padding: 0 0.5rem;
	color: var(--font-dark-color);
	border-radius: var(--radius);
	background-color: #f8f8f880;
	word-break: break-all;
	white-space: pre-wrap;
	overflow: hidden;
	height: 0;
	max-height: 5rem;
	overflow-y: overlay;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.prompt::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.prompt::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 3px;
	border: none;
}

.prompt.show {
	height: fit-content;
	padding: 0.5rem;
}