.menuCon {
	width: max-content;
	margin-right: 2rem;
	transition: opacity 0.3s ease;
	opacity: 1; 
	visibility: visible;
}
.conHide.menuCon{
	opacity: 0;
	visibility: hidden;
}
.hideMenuCon.menuCon{
	opacity: 0;
}
.menu {
	cursor: pointer;
	position: relative;
	height: 3rem;
	width: 6rem;
	border: 2px solid;
	border-radius: 1.5rem;
	border-color: transparent;
	background-color: transparent;
	text-align: center;
	line-height: 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	color: var(--menuColor);
}

.menu:hover {
	color: var(--menuSelectColor);
	background-color: var(--menuBg);
}

.close {
	position: absolute;
	cursor: pointer;
	user-select: none;
	width: 1rem;
	height: 1rem;
	/* color: var(--font-dark-color); */
	right: 0.3rem;
	top: 0.9rem;
	text-align: center;
	line-height: 0.9rem;
	font-size: 1rem;
	backdrop-filter: blur(5px);
	color: var(--menuSelectColor);
	transition: all 0.3s;
	opacity: 1;
	pointer-events: none;
}

.menu:hover .close {
	opacity: 1;
	pointer-events: auto;
}

.menu.selected {
	color: var(--menuSelectColor);
	border-color: var(--menuSelectBoderColor);
	background-color: var(--menuBg);
}

.RouletteOut {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 500px;
	width: 120px;
	height: 120px;
	position: fixed;
	bottom: 1.5vw;
	left: 3vw;
	transform-origin: center center;
	transition: all 0.3s ease;
	overflow: hidden;
	cursor: pointer;
}

.RouletteIn {
	border-radius: 100px;
	width: 88px;
	height: 88px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center center;
	transition: all 0.3s ease;
	z-index: 1;
	overflow: hidden;
}

.RouletteOut.menuShow {
	width: 337px;
	height: 337px;
	bottom: calc(1.5vw - 108px);
	left: calc(3vw - 108px);
}

.RouletteIn.menuShow {
	width: 112px;
	height: 112px;
}

.circleContainer {
  position: relative;
  width: 337px;
  height: 337px;
  border-radius: 50%;
  /* background-color: #F9A825; */
  overflow: hidden;
	z-index: -1;

}
.panoramaWrapper{
	position: relative;
}
.sectorBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform-origin: 0 0;
		font-size: 14px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #FFFFFF;
	/* background-color: yellow;
	clip-path: inset(0 0 round -25% 0); */
}

.sectorBox span{
	position: absolute;
}

.sectorBox:nth-child(1) {
  transform: rotate(-160deg) skewY(40deg);
}
.sectorBox:nth-child(1) span{
	transform:  skewY(-40deg) rotate(160deg);
}

.sectorBox:nth-child(2) {
  transform: rotate(-110deg) skewY(40deg);
}
.sectorBox:nth-child(2) span{
	transform: skewY(-40deg)  rotate(110deg);
}

.sectorBox:nth-child(3) {
  transform: rotate(-60deg) skewY(40deg);
}
.sectorBox:nth-of-type(3) span{
	transform: skewY(-40deg) rotate(60deg) ;
}
.sectorBox:hover{
	background: linear-gradient(120deg, rgba(215,255,55,0.9) 0%, rgba(215,255,55,0.8) 30%, rgba(215,255,55,0.18) 50%, rgba(74,0,224,0) 60%, rgba(215,255,55,0) 60%);
	box-shadow: 0px 0px 16px 0px rgba(200,173,255,0.8);
	color: #373737;
}
