.con {
	position: fixed;
	right: -8px;
	top: 0;
	height: 100vh;
	width: 100vw;
	background: #00000080;
	z-index: 9;
	color: var(--font-light-color);
	overflow: auto;
	transition: transform 300ms;
}

.mask {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
}

.loginCon {
	position: absolute;
	top: 0;
	right: 0;
	height: 100vh;
	width: 35rem;
	padding: var(--side-gap);
	background-color: var(--bg-dark-color);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	transition: transform 0.3s ease-in-out;
	transform-origin: right center;
}

.title {
	font-family: 'Courier New';
	font-size: 3rem;
	font-weight: bold;
	line-height: 3rem;
	margin-bottom: 3rem;
	color: white;
}

@media (max-height: 700px) {
	.title {
		display: none;
	}
}


.btn {
	width: 100%;
	height: 3rem;
	line-height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid;
	border-radius: var(--radius);
	cursor: pointer;
	user-select: none;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.github.btn {
	border-color: #707070;
	background-color: #ffffff05;
	font-family: 'Roboto', sans-serif;
}
.github.btn svg{
    margin: 0 1rem;
}

.github.btn:hover {
	background-color: #ffffff20;
}

.google.btn {
	font-family: 'Roboto', sans-serif;
	border-color: #fff;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.54);
}

.google.btn:hover {
	background-color: #eeeeee;
}

.google.btn img {
	height: 3rem;
	width: 3rem;
}

.splitCon {
	position: relative;
	margin-bottom: 1rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 0rem;
}

.split {
	width: 100%;
	border: none;
	border-top: 1px solid var(--bg-gray4-color);
	height: 0;
}

.splitCon .text {
	background-color: var(--bg-dark-color);
	position: absolute;
	top: 0;
	left: 50%;
	line-height: 1rem;
	margin-left: -1.5rem;
	user-select: none;
	padding: 0 0.5rem;
}

.label {
	padding-left: 1rem;
	width: 100%;
	text-align: left;
	margin-bottom: 0.5rem;
	margin-top: 1rem;
}

.ipt {
	width: 100%;
	height: 3rem;
	border: none;
	outline: none;
	padding: 1rem;
	line-height: 1rem;
	border-radius: var(--radius);
	background-color: var(--bg-gray3-color);
	margin-bottom: 1rem;
	color: var(--font-light-color);
	font-size: 1rem;
}

.ipt:focus {
	background-color: var(--bg-gray4-color);
}

.remCon {
	display: flex;
	width: 100%;
	justify-content: space-between;
	color: var(--font-gray2-color);
	margin-bottom: 1rem;
}

.remCon input {
	margin-right: 0.5rem;
}

.remCon span {
	cursor: pointer;
	user-select: none;
}

.remCon span:hover {
	color: #fff;
}

.spaceholder {
	flex-grow: 1;
	flex-shrink: 1;
}

.sign.btn {
	border-color: var(--theme-color);
	background-color: var(--theme-color);
	color: #fff;
	font-weight: bold;
}

.sign:hover {
	background-color: transparent;
	color: var(--theme-color);
}

.tips {
	/* font-size: .8rem; */
	line-height: 1rem;
	color: red;
	width: 100%;
	text-align: left;
	margin-bottom: 1rem;
}

.foot {
	width: 100%;
	height: 2rem;
	margin-bottom: 0rem;
	text-align: left;
}

.foot span {
	cursor: pointer;
}

.loginConTransition-enter {
	transform: translateX(100%);
}

.loginConTransition-enter-active {
	transform: translateX(0%);
	transition: transform 300ms;
}

.loginConTransition-exit {
	transform: translateX(0%);
}

.loginConTransition-exit-active {
	transform: translateX(100%);
	transition: transform 300ms;
}

.fadeInFadeOutEnter {
	opacity: 0;
	transform: translateY(-10px);
}

.fadeInFadeOutEnterActive {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 300ms, transform 300ms;
}

.fadeInFadeOutExit {
	opacity: 1;
	transform: translateY(0);
}

.fadeInFadeOutExitActive {
	opacity: 0;
	transform: translateY(-10px);
	transition: opacity 300ms, transform 300ms;
}
.fadeComponent {
	opacity: 0;
}
.successTips {
	color: green;
}

.errorTips {
	color: red;
}

.fileInputWrapper {
	position: relative;
	display: inline-block;

	text-align: center;
	font-size: 14px;
	overflow: hidden;

	width: 100%;
	height: 3rem;
	line-height: 3rem;
	justify-content: center;
	align-items: center;
	border: 1px solid;
	border-radius: var(--radius);
	cursor: pointer;
	user-select: none;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.fileInput {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}
