@font-face {
	font-family: 'CourierNewCustom';
	src: url('../../../assets/fonts/courbd.ttf') format('truetype');
}

.chatBox {
	width: 50%;
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-left:25px;
	z-index: -1;
}

.chatTitleBox{
	width: 100%;
	display: flex;
	padding: 8px 16px 8px 0;
	box-sizing: border-box;
	align-items: center;
	font-size: 1.2rem;
	font-weight: bold;
	color: var(--font-gray-color);
	margin-bottom: 1rem;
}

.restart {
	display: flex;
	line-height: 1rem;
	margin-bottom: 0;
	margin-left: 1rem;
	font-weight: 400;
	font-size: 1rem;
	user-select: none;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 5px;
}

.restart:hover {
	color: var(--font-dark-color);
	background-color: var(--bg-gray0-color);
}
.restartIcon{
  width: 12px;
}
.spaceholder {
	flex-grow: 1;
	flex-shrink: 1;
}

.lang {
	font-weight: 400;
	cursor: pointer;
	user-select: none;
	margin-right: 1rem;
}

.lang.en {
	width: 4rem;
	margin-left: 1rem;
	margin-right: 0;
}

.lang:hover {
	font-weight: bold;
}

.lang.selected {
	color: var(--font-dark-color);
	font-weight: bold;
}

.regene {
	font-family: Arial-Regular, Arial;
	cursor: pointer;
	user-select: none;
	color: var(--theme-color);
	font-weight: 400;
}
.regene:hover{
	  color: #6f2fee;
}
.chatCon {
	color: var(--font-dark-color);
	background-color: var(--bg-gray0-color);
	flex-grow: 1;
	flex-shrink: 1;
	padding: 1rem;
	width: 100%;
	height: calc(100% - 4rem);
	border-radius: var(--radius);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.chatMsgCon {
	flex-grow: 1;
	flex-shrink: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow: auto;
	text-align: left;
}

.chatMsgCon::-webkit-scrollbar-thumb {
	border: none;
	border-radius: 3px;
}

.chatMsgCon::-webkit-scrollbar {
	width: 0px;
}

.chatMsgRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.chatMsgRow.user {
	flex-direction: row-reverse;
}

.avatar {
	height: 3rem;
	min-width: 3rem;
	width: 3rem;
	min-height: 3rem;
	border-radius: 1.5rem;
	overflow: hidden;
	/* background: var(--bg-gray2-color); */
	margin-right: 1rem;
	margin-left: 0;
}

.avatar img {
	height: 3rem;
	width: 3rem;
}

.avatarAssistant {
	height: 3rem;
	min-height: 3rem;
	min-width: 3rem;
	width: 3rem;
	border-radius: 1.5rem;
	overflow: hidden;
	/* background: var(--bg-gray2-color); */
	margin-right: 1rem;
	margin-left: 0;
	background-color: #fff;
	filter: gray;
}

.avatarAssistant img {
	height: 3rem;
	width: 3rem;
	left: 0rem;
	-webkit-filter: grayscale(0.5);
	/* Webkit */
}

.user .avatar {
	margin-left: 1rem;
	margin-right: 0;
}
.notificationText {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 100%;
}
.notificationBubble {
	/* margin: 0 1rem; */
	padding: 0.75rem 1rem;
	line-height: 1.5rem;
	flex-shrink: 1;
	flex-grow: 1;
	/* background: var(--bg-theme-color); */
	border-radius: var(--radius);
	word-break: break-all;
	white-space: pre-wrap;
}

.notificationBubble.unactive {
	color: rgb(170, 170, 170, 1);
}

.bubble {
	/* margin: 0 1rem; */
	padding: 0.75rem 1rem;
	line-height: 1.5rem;
	flex-shrink: 1;
	background: var(--bg-theme-color);
	border-radius: var(--radius);
	word-break: break-all;
	white-space: pre-wrap;
	margin-right: 1rem;
}

.bubble.unactive {
	background: var(--bg-gray1-color);
}

.user .bubble {
	background: var(--bg-light-color);
	margin-right: 0;
	margin-left: 1rem;
}

.chatIptCon {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.chatTipsCon {
	display: flex;
	width: 100%;
}

.chatTipsCon::-webkit-scrollbar {
	height: 0px;
}

.chatTips {
	flex-shrink: 0;
	padding: 1rem;
	width: 15.5rem;
	background: var(--bg-light-color);
	border-radius: var(--radius);
	margin-right: 1rem;
	text-align: left;
	word-break: break-all;
	white-space: pre-wrap;
	cursor: pointer;
}

.chatTips:last-child {
	margin-right: 0;
}

.chatRowCon {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 1rem;
}

.iptLineCon {
	margin-right: 1rem;
	text-align: left;
	line-height: 1.5rem;
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;
}

.iptSpaceholder {
	visibility: hidden;
	padding: 0.75rem 1rem;
	max-height: calc(9rem + 2px);
	border: 1px solid transparent;
	word-break: break-all;
	white-space: pre-wrap;
}

.ipt {
	background: var(--bg-light-color);
	border-radius: var(--radius);
	padding: 0.75rem 1rem;
	word-break: break-all;
	white-space: pre-wrap;
	overflow-y: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	/* firefox */
	width: 100%;
	height: 100%;
}

.ipt::-webkit-scrollbar-thumb {
	border-color: var(--bg-light-color);
}

.btn {
	height: calc(3rem - 2px);
	line-height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: var(--theme-color);
	border-radius: 1.5rem;
	padding: 0 1.5rem;
	user-select: none;
	cursor: pointer;
	font-weight: bold;
	border: 1px solid var(--theme-color);
}

.btn:hover {
	background: transparent;
	color: var(--theme-color);
}

.con .btn.disabled {
	cursor: not-allowed;
	background: var(--bg-gray2-color);
	border-color: var(--bg-gray2-color);
	color: var(--font-light-color);
}

.btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}


.dot {
	animation: blink 1s linear infinite;
}

@keyframes blink {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
