.Img3dProdWrapper {
  width: 45%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 25px;
  user-select: none;
}

.titleBox {
  width: 100%;
  display: flex;
  color: #AAAAAA;
  justify-content: space-between;
  align-items: flex-end;
  vertical-align: bottom;
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--font-gray-color);
  margin-bottom: 1rem;
  height: 48px;
}

.reGenerate {
  cursor: pointer;
  color: #4a00e0;
  font-weight: 400;
}

.reGenerate:hover {
  color: #6f2fee;
}

.title {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #AAAAAA;
  margin-right: 5px;
}

.restartIcon {
  width: 12px;
}

.mainBox {
  background-color: #F8F8F8;
  border-radius: 8px;
  padding: 4% 8% 4%;
  width: 100%;
  height: calc(100% - 62px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.switchBox {
  display: flex;
  justify-content: space-between;
  color: #AAAAAA;
  font-size: 16px;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  vertical-align: bottom;
  align-items: center;
}

.switch {
  align-self: flex-end;
  position: relative;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  background: rgba(74, 0, 224, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  overflow: hidden;
  cursor: pointer;
}

.switch img {
  display: block;
  height: 24px;
  width: 24px;
}

.switch:hover {
  background: rgba(74, 0, 224, 0.15);
}

.topBox {
  width: 100%;
  height: fit-content;
  position: relative;
  top: 2%;
}

.filterBox {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1.15;
  border-radius: 8px;
  position: absolute;
  height: fit-content;
  left: 0;
  top: 0;
  transition: all 0.2s linear;
  opacity: 0;
  overflow: hidden;
  z-index: 0;
}

.imgBox {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1.15;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
}

.imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.FrameBox {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  transform: translate(calc(-50% + 1px),-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.FrameBox :nth-of-type(1),
.FrameBox :nth-of-type(2),
.FrameBox :nth-of-type(3) {
  display: block;
  width: 10%;
  border-radius: 50%;
  background: linear-gradient(90deg, #B4B4B4 0%, #EDEDED 63.02%, #F5F5F5 100%);
  box-shadow: -0.5px 0px 1px 0px #3A3A3A33;

}

.FrameBox :nth-of-type(1) {
  position: relative;
  left: 4px;
  height: 38%;
}

.FrameBox :nth-of-type(2) {
  height: 45%;
}

.FrameBox :nth-of-type(3) {
   position: relative;
  right: 3px;
  height: 38%;
}

.overlayBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  background: var(--cardSkeletonBg);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.prevewImgBox {
  border-radius: 8px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1.15;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s linear;
  opacity: 0;
  z-index: 0;
}

.prevewImgBox img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeHodler{
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1.15;
  margin-bottom: 12px;
}
.photoList{
   display: flex;
    width: 100%;
}

.photoImgBox {
  flex-shrink: 0;
  background-color: #EEEEEE;
  border-radius: 6px 6px 6px 6px;
  margin-right: 12px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  aspect-ratio: 1/1;
}

.photoImg {
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 6px 6px;
  pointer-events: none;
}

.normalItem {
  position: absolute;
  display: block;
  width: 7px;
  height: 100%;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 6px 0 0 6px;
}
