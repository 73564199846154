@font-face {
	font-family: 'CourierNewCustom';
	src: url('../../../assets/fonts/courbd.ttf') format('truetype');
}

.generateBox{
	background-color: #fff	;
	height: 100%;
	position: relative;
	width: 50%;
	overflow: hidden;
}

.titleBox {
	width: 100%;
	display: flex;
	padding: 0 16px 0 0;
	box-sizing: border-box;
	align-items: center;
	margin-bottom: 1rem;
}
.title {
	font-size: 1.2rem;
	font-weight: bold;
	color: var(--font-gray-color);
	margin-right: 20px;
	text-align: center;
	line-height: 1.2rem;
}
.spaceholder {
	flex-grow: 1;
	flex-shrink: 1;
	margin: 0;
}

.btn {
	width: 97%;
		padding: 0 1.5rem;
	margin-bottom: 1rem;
	height: calc(3rem - 2px);
	line-height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: var(--theme-color);
	border-radius: 1.5rem;

	user-select: none;
	cursor: pointer;
	font-weight: bold;
	border: 1px solid var(--theme-color);
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
}

.btn:hover {
	background: transparent;
	color: var(--theme-color);
}

 .btn.disabled {
	cursor: not-allowed;
	background: var(--bg-gray2-color);
	border-color: var(--bg-gray2-color);
	color: var(--font-light-color);
}


.iptArea {
	height: 12rem;
	width: 100%;
	background: var(--bg-gray0-color);
	border-radius: var(--radius);
	padding: 1rem;
	text-align: left;
	margin-bottom: 1rem;
}

.iptArea::-webkit-scrollbar-thumb,
.candidateCon::-webkit-scrollbar-thumb {
	border-color: var(--bg-gray0-color);
}

.iptArea::-moz-scrollbar-thumb,
.candidateCon::-moz-scrollbar-thumb {
	border-color: var(--bg-gray0-color);
}

.iptArea::-ms-scrollbar-thumb,
.candidateCon::-ms-scrollbar-thumb {
	border-color: var(--bg-gray0-color);
}

.candidateCon {
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;

	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 1rem;
	/* background-color: var(--bg-gray0-color); */
	border-radius: var(--radius);
	height: 40vh;
}

.candidateCon::-webkit-scrollbar-thumb {
	border-color: var(--bg-gray0-color);
}

.candidateCol {
	position: relative;
	width: calc((100% - 2rem) / 3);
	margin-right: 1rem;
	z-index: 1;
}

.candidateCol:last-child {
	margin-right: 0;
}

.candidateCol img {
	width: 100%;
	margin-bottom: 1rem;
	border-radius: var(--radius);
	cursor: pointer;
}

.modelInfoConWithBackground {
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 10000;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	padding: 10px;
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	right: 1rem;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.progressInfo {
	width: 100%;
	margin-bottom: 0.5rem;
}

.progressTrack {
	width: 100%;
	height: 4px;
	border-radius: 2px;
	position: relative;
	background: var(--bg-light-color);
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	margin-bottom: 1rem;
}

.progressThumb {
	height: 4px;
	border-radius: 2px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--theme-color);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.modelInfoCon {
	padding: 10px;
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	right: 1rem;
	text-align: left;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.highlight {
	border: 1px solid var(--theme-color);
	box-sizing: border-box;
}

.stringSelectionContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	padding: 0rem;
	margin-top: 0.1rem;
}

.stringSelectionButton {
	background-color: #f5f5f5;
	color: #7f7f7f;
	border: none;
	padding: 6px 13px;
	border-radius: 5px;
	cursor: pointer;
	white-space: nowrap;
	text-align: center;
	font-size: 15px;
	line-height: 1.6;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}

.stringSelectionButton:hover {
	background-color: #d7d7d7;
	color: #363636;
}

.stringSelectionButton.selected {
	background-color: #d7d7d7;
	color: #363636;
}

.checkIcon {
	font-size: 1rem;
	color: #363636;
}

.seedsArea {
	bottom: 0px;
	left: 0px;
	position: absolute;
	height: 2rem;
	width: 33%;
	background: var(--bg-gray0-color);
	border-radius: var(--radius);
	padding: 1rem;
	text-align: center;
	/* margin-top: 21rem;
	margin-left: -20rem; */
	border: 2px solid #fff;
	font-size: 12px;
	transition: border-color 0.3s;
	z-index: 10000;
}

.seedsArea:hover {
	cursor: pointer;
}

.seedsArea:focus {
	border-color: var(--theme-color);
	outline: none;
}

.generateTips {
	top: 1rem;
	position: relative;
	z-index: 1;
	width: 100%;
	padding: 0rem;
	text-align: left;
	font-size: 10px;
	line-height: 1.2;
	color: #7f7f7f;
}

@media (max-height: 1010px) {
	.generateTips {
		display: none;
	}
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}