@font-face {
	font-family: 'CourierNewCustom';
	src: url('../../../assets/fonts/courbd.ttf') format('truetype');
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.cardBox {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1200;
	transform: translate(-50%,-50%);
	background-color: #fff;
	height: 80vh;
	width: auto;
	aspect-ratio: 3/2;
	min-width: 790px;
	min-height: 560px;
	border-radius: var(--radius);
	padding: 1% 1% 1%;
	display: flex;
	align-items: center; 
}


