.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
}

.scrollBox {
  position: relative;
  box-sizing: border-box;
  transform-origin: 0 0;
  overflow: auto;
  /* 隐藏水平滚动轴 显示竖直滚动轴 */
  overflow-x: hidden;
  direction: rtl;
}

.content {
  position: absolute;
  left: 100%;
  /* 宽度自动，高度和最顶层的宽度一致 */
  box-sizing: border-box;
  transform-origin: 0 0;
  transform: rotate(90deg);
  display: flex;
  direction: ltr;
}

.scrollBox.showBar::-webkit-scrollbar {
  height: 7px !important;
  opacity: 0 !important;
  transition: opacity 0.3s;
  display: block;
}

.scrollBox.showBar:hover::-webkit-scrollbar {
  opacity: 1 !important;
  display: block;
  transition: all 0.3s linear;
}

.scrollBox.showBar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2) !important; 
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.2) !important;
  border: 5px solid;
   transition: all 0.3s linear;
}