@font-face {
	font-family: 'CourierNewCustom';
	src: url('./assets/fonts/courbd.ttf') format('truetype');
}

html { 
overflow-y: overly;
overflow-x: hidden;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--bgColor);
	color: var(--themeTextColor);
	box-sizing: border-box;
	transition: all 0.5s linear;
	background-image: var(--lightbg);
	opacity: 1;
}
a {
  color: inherit;
  text-decoration: none;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 8px;
	border: 5px solid;
	border-color: #121317;
}
::-webkit-scrollbar-corner {
	display: none;
}
body > a {
	display: none;
}

.no-select {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
/* 默认暗色主题 */
:root{
	--logoFilter:brightness(100%);
	--themeTextColor:#CFCFCF;
	--bgColor:#121317;
	--headTextColor:#CFCFCF;
	--headTextHoverColor:#fff;
	--headAvatarColor:#CFCFCF;
  --headBoderColor:rgba(255, 255, 255, 0.1);
	--navColor:#9c9c9c;
	--navHoverColor: #fff;
	--balanceBg:#262626;
	--balanceColor:#CFCFCF;
	--menuBg:#373737;
	--menuSelectBg:#fff;
	--menuSelectColor:#fff;
	--menuSelectBoderColor:#fff;
	--loadingBoxBg:#2C2C2C;
	--cardSkeletonBg:linear-gradient(90deg, #2f2f2f 25%, #232323 50%, #3d3d3d 75%);
	--cardBg:#373737;
	--galleryPrivateTipBg:#181818;
	--galleryPrivateColor:#fff;
	--moreHoverBg:#00000050;
	--moreColor:#CFCFCF;
	--footerImgBrightness:brightness(100);
	--noResultBg: rgba(0, 0, 0, 0.5);
	--noResultText: #cfcfcf;
	--topUpBg: #2c2c2c;
	--userTitleBg: #404040;
	--pageNumberBg:rgba(72, 0, 220, 1);
}
/* 亮色主题 */
html[data-theme='light']{
	--logoFilter:brightness(0%);
	--lightbg:url('./assets/imgto3dBg.png');
	--themeTextColor:#353535;
	--bgColor:rgba(255, 255, 255, 0.07);;
	--headTextColor:#353535;
	--headTextHoverColor:#1C1C1C;
	--headAvatarColor:#353535;
  --headBoderColor:#efefef;
	--navColor: rgba(9, 8, 48, 0.3);
	--navHoverColor:  #090830;
	--balanceBg:#fff;
	--balanceColor:#353535;
	--menuBg:rgba(156, 184, 255, 0.3);
	--menuColor: #646464;
	--menuSelectColor:#043AC6;
	--menuSelectBoderColor:#9CB8FF;
	--loadingBoxBg:#ffffff;
	--cardBg:#bdb7b7;
	--cardSkeletonBg:linear-gradient(90deg, #CFEDFE 0%, #D6D7FF 100%);;
	--galleryPrivateTipBg:#FBFCFF;
	--galleryPrivateColor:#404040;
	--moreHoverBg:rgba(64,64,64,0.08);
	--moreColor:#3F3F3F;
	--footerImgBrightness:brightness(0);
	--noResultBg: rgba(219, 218, 218, 0.5);
	--noResultText: #646464;
	--topUpBg: #ffffff;
	--userTitleBg: #d7d7d77a;
	--pageNumberBg:rgba(72, 0, 220, 0.4);
} 
/* panorama主题 */
html[data-theme='panorama']{
	--logoFilter:brightness(100%);
	--themeTextColor:#e6e6e6;
	--bgColor:#121317;
	--headTextColor:#fff;
	--headTextHoverColor:#fff;
	--headAvatarColor:#e6e6e6;
  --headBoderColor:rgba(255, 255, 255, 0.1);
	--navColor:#e6e6e6;
	--navHoverColor: #fff;
	--balanceBg:#fff;
	--balanceColor:#353535;
	--menuBg:#373737;
	--menuSelectBg:#fff;
	--menuSelectColor:#fff;
	--menuSelectBoderColor:#fff;
	--loadingBoxBg:#2C2C2C;
	--cardSkeletonBg:linear-gradient(90deg, #2f2f2f 25%, #232323 50%, #3d3d3d 75%);
	--cardBg:#373737;
	--galleryPrivateTipBg:#181818;
	--galleryPrivateColor:#fff;
	--moreHoverBg:#00000050;
	--moreColor:#e6e6e6;
	--footerImgBrightness:brightness(100);
	--noResultBg: rgba(0, 0, 0, 0.5);
	--noResultText: #cfcfcf;
	--topUpBg: #2c2c2c;
	--userTitleBg: #404040;
} 
#root {
	text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
	--theme-color: #4a00e0;
  --bg-theme-color: #f5f0ff;
	--font-dark-color: rgb(67, 67, 67);
	--font-gray-color: #aaa;
	--font-gray2-color: #989898;
	--font-light-color: #cfcfcf;
	--bg-light-color: #fff;
	--bg-gray0-color: #f8f8f8;
	--bg-gray1-color: #ececec;
	--bg-gray2-color: #7c7c7c;
	--bg-gray3-color: #373737;
	--bg-gray4-color: #262626;
	--bg-theme-color: #e7e3f1;
	--bg-dark-color: #121317;
	--side-gap: 5rem;
	--radius: 0.5rem;
	--shadow: -10px 0px 12px 1px rgba(135, 135, 135, 0.16);
    display: "flex";
    flex-direction: "column";
    min-height: "100vh";
}

#root * {
    box-sizing: border-box;  
}
/* Scrollbar */
/* Firefox */
#root * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}
#root *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
#root *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}

/* Chrome, Safari, Edge */
@media (hover: none), (pointer: coarse) {
    #root * {
        -webkit-overflow-scrolling: touch;
    }
}
@media (hover: hover), (pointer: fine) {
    #root *::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

