.recharge-popup {
  position: absolute;
  top: calc(100% + 2.5rem);
  right: 1rem;
  background-color: var(--topUpBg);
  border: none;
  border-radius: 1rem;
  padding: 0.625rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.0625rem;
  z-index: 2;
  min-width: 25rem;
  overflow: hidden;
  opacity: 0;
}

.recharge-popup-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.recharge-popup.show {
  display: flex;
}

.recharge-popup-step1,
.recharge-popup-step2,
.recharge-popup-step3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recharge-popup-history{
  position: relative;
}
.recharge-popup-history span{
    position: absolute;
    top: 4px;
    right: -40%;
    font-size: 11px;
    font-family: Arial-Regular, Arial;
    font-weight: 400;
    color: #8E8E8E;
}
.recharge-popup-history-title::before{
  position: absolute;
  left: -18px;
  top: 0px;
  display: block;
  content: '';
  background: url('../../../assets/clock.svg');
  background-size: 100%;
  width: 13px;
  height: 13px;
}
.recharge-popup-payment-methods {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.recharge-popup-radio {
  display: none;
}


.recharge-popup-method {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.recharge-popup-input {
  margin: 0.625rem 0;
  padding: 0.3125rem;
  border: 0.0625rem solid var(--balanceBg);
  border-radius: 0.3125rem;
  outline: none;
  color: var(--headTextHoverColor);
  width: 92%;
  height: 3rem;
  background-color: var(--balanceBg);
  margin-left: 1rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.0625rem;
  margin-bottom: 1rem;
}
.recharge-popup-select {
  margin: 0.625rem 0;
  padding: 0.3125rem;
  border: 0.0625rem solid var(--balanceBg);
  border-radius: 0.3125rem;
  outline: none;
  color: var(--headTextHoverColor);
  width: 30%;
  height: 3rem;
  background-color: var(--balanceBg);
  margin-left: 0.3125rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.get-redeem-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  color: #ccc;
  transition: background-color 0.3s;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  justify-content: center;
}

.recharge-popup-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  color: var(--headTextColor);
  padding: 0.75rem 1rem;
  transition: background-color 0.3s;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  margin-top: 0rem;
  justify-content: center;
}
.recharge-popup-button span {
  line-height: normal;
}
.recharge-popup-button:last-child {
  border-top: 0.0625rem solid var(--noResultBg);
  border-radius: 1rem;
}
.recharge-popup-button:hover {
  background-color: var(--noResultBg);
  border-radius: 1rem;
}

.recharge-popup-fade {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear, visibility 0.3s;
}

.recharge-popup-fade.show {
  visibility: visible;
  opacity: 1;
}
.recharge-popup-method:hover .recharge-popup-method-icon {
  background-color: #3a9ad9;
}
.recharge-popup-amount-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.625rem;
}

.recharge-popup-amount-options label {
  cursor: pointer;
}

.recharge-popup-amount-options input[type="radio"] {
  display: none;
}

.recharge-popup-payment-methods {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.recharge-popup-button.selected {
  background-color: #404040;
}

.recharge-popup-button span {
  line-height: normal;
}
