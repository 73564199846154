.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.Img3dGenerateWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1200;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 446px;
  height: 580px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 22px 32px;
  user-select: none;
}

.titleBox {
  width: 100%;
  display: flex;
  color: #AAAAAA;
  justify-content: flex-start;
  align-items: center;
  vertical-align: bottom;
  margin-bottom: 15px;
}

.title {
  font-size: 18px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  margin-right: 10px;
}

.restartIcon {
  width: 16px;
}

.mainBox {
  width: 100%;
  height: fit-content;
  background: #F8F8F8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 16px 22px;
}
/* .mainBox.unable:hover{
  cursor:not-allowed!important;
} */
.settingBox {
  width: 100%;
  height: 233px;
  margin-bottom: 18px;
}

.settingTitle {
  text-align: left;
  font-size: 16px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  color: #393939;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin: 12px 0 10px;
}

.toolbar div:nth-of-type(1) {
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #707070;
}

.defaultBox {
  width: 70px;
  height: 26px;
  background: rgba(203, 187, 251, 0.4);
  border-radius: 6px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #4A00E0;
  position: relative;
  margin-top: -5px;
  cursor: pointer;
}

.defaultBox span {
  display: inline-block;
  font-size: 24px;
  transform: scale(0.5);
  padding-left: 2px;
}

.defaultBox::before {
  position: absolute;
  background-image: url('../../../assets/defaultIcon.png');
  background-size: 100%;
  content: '';
  width: 16px;
  height: 16px;
  display: block;
  left: 4px;
  top: 50%;
  transform: translateY(-45%);
}

.controlerBox {
  width: 100%;
  height: 56px;
  background: #EEEEEE;
  padding: 6px 7px;
  border-radius: 8px;
  display: flex;
  position: relative;
}

.controlerBox div {
  height: 44px;
  position: absolute;
}

.controlerBox .controlerSelect {
  border-radius: 6px !important;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 6px 0px rgba(137, 135, 251, 0.5);
  z-index: 1000 !important;
  height: 46px;
  transform: translateY(-1px);
}

.leftControler,
.rightControler {
  position: absolute;
  z-index: 2;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.75);
}

.leftControler::before,
.rightControler::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 6px;
  border: 1px solid;
  background-image: linear-gradient(222deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
}

.leftControler {
  transform: translate(-50%, -50%);
  left: 0;
}

.leftControler span,
.rightControler span {
  position: absolute;
  display: block;
  width: 2px;
  height: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  top: 50%;
  border-radius: 1px;
}

.leftControler span:nth-of-type(1),
.rightControler span:nth-of-type(1) {
  left: 20%;
  transform: translateY(-50%);
}

.leftControler span:nth-of-type(2),
.rightControler span:nth-of-type(2) {
  left: 50%;
  transform: translate(-50%, -50%);
}

.leftControler span:nth-of-type(3),
.rightControler span:nth-of-type(3) {
  right: 20%;
  transform: translateY(-50%);
}

.rightControler {
  transform: translate(50%, -50%);
  right: 0;
}
.photoList{
   display: flex;
    width: 100%;
}

.photoitem {
  flex-shrink: 0;
  background-color: #EEEEEE ;
  width: 92px;
  height: 103px;
  border-radius: 10px;
  margin-right: 12px;
  position: relative;
  transition: opacity 0.3s linear;
  opacity: 1;
}

.photoitem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  border-radius: 6px;
}
.photoitem:hover .deletWights{
  display: block;
}
.deletWights{
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  display: none;
}
.deletWights img{
  width: 100%;
  height: 100%;
}
.normalItem {
  position: absolute;
  z-index: 1;
  display: block;
  width: 4px;
  height: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 16px 0 0 16px;
}

.cameraBox {
  height: 155px;
  width: 100%;
}

.cameraTitle {
  display: flex;
  justify-content: space-between;
}

.leftTitle {
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #707070;
  text-align-last: left;
}

.rightTitle {
  color: #4A00E0;
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  cursor: pointer;
  position: relative;
}

.optionsBox {
  position: absolute;
  bottom: -350%;
  left: -62%;
  transition: all 0.3s ease;
  width: 169px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px #4E4E4E1A;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px 10px 8px 12px;
  z-index: 100000;
}

.optionsBox.showOption {
  visibility: visible;
  opacity: 1;
}

.optionsBox.hideOption {
  visibility: hidden;
  opacity: 0;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  width: 100%;
}
.checkbox {
  position: relative;
}
.inputInfo {
  font-size: 12px;
  display: block;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #646464;
  flex-grow: 1;
  height: 16px;
  line-height: 16px;
  width: 100%;
    text-align: right;
}

.label {
  position: relative;
  top: 1px;
  width: 11px;
  height: 11px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  vertical-align: middle;
  transition: background 0.1s ease;
  cursor: pointer;
  display: block;
}

.label:hover {
  border-color: #d7d7d7;
}

.label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 5px;
  opacity: 0;
  transform: translate(-50%, -55%) rotate(45deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: all 0.3s ease;
  transition-delay: 0.15s;
}


.hiddenxsup:checked~.label {
  border-color: transparent;
  background: #165DFF;
  animation: jelly 0.6s ease;
}

.hiddenxsup:checked~.label:after {
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%) rotate(45deg) scale(1);
}

@keyframes jelly {
  from {
    transform: scale(1, 1);
  }

  30% {
    transform: scale(1.25, 0.75);
  }

  40% {
    transform: scale(0.75, 1.25);
  }

  50% {
    transform: scale(1.15, 0.85);
  }

  65% {
    transform: scale(0.95, 1.05);
  }

  75% {
    transform: scale(1.05, 0.95);
  }

  to {
    transform: scale(1, 1);
  }
}

.hiddenxsup {
  display: none !important;
}

.skinToneLine{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  position: relative;
}
.skinToneLine::before{
  position: absolute;
  content: '';
  height: 2px;
  top: -12px;
  left: 0;
  background-color: #f3f3f3;
  width: 100%;
}

.colorPalette{
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
}

.colorItem{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #333;
  font-size: 12px;
  height: 20px;
  border-radius: 4px;
  margin: 4px;
}
.nomarlItem{
  flex: 1 0 calc(25% - 8px); /* 每行最多4个元素，占据宽度的25%减去margin */
}
.specialItem{
  flex: 1 0 calc(50% - 8px);
}
.cameraMainBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cameraLeftBox {
  width: 250px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.selectBox {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
}

.cameraSelect {
  width: 108px;
  height: 43px;
  border-radius: 8px;
  margin-right: 12px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #EEEEEE;
  transition:all 0.2s ease;
  position: relative;
}

.cameraSelect span {
  display: inline-block;
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #232323;
}

.cameraSelect:hover {
  background: #E7E7E7;
}

.cameraSelect.selected {
  background-color: #4A00E0;
}

.cameraSelect.selected span {
  color: #fff;
}
.cameraSelect:hover::after{
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  color: #404040;
  font-size: 10px;
  font-family: Arial;
  text-align: left;
  min-width: 135px;
  padding: 6px 10px ;
  border-radius: 4px;
  background: #ECECEC;
  transform: translateY(calc(-100% - 10px));
  z-index: 10;
}
.cameraSelect:nth-of-type(1):hover::after{
 content: 'Ideal for long-distance shots, parallel projection, and concept art.';
}
.cameraSelect:nth-of-type(2):hover::after{
 content: 'Suitable for half-body portraits.';
}
.cameraSelect:nth-of-type(3):hover::after{
 content: 'Perfect for close-ups, selfies, and near-distance photography.';
}
.cameraSelect:nth-of-type(4):hover::after{
 content: 'Please enter the 36mm equivalent focal length of the original image.';
}

.customBox{
  position: relative;
  border: 2px solid transparent;
}

.hideBefore.customBox.tipAnimal{
  /* border:2px solid #165DFF; */
  border-color: rgb(235, 8, 8);
  animation: outLine 2s ;
}

.cameraSelect .customspan{
  margin-right: 12px;
  color: #A6A6A6;;
}
.hideBefore.customBox{
  border: 2px solid #4A00E0;
   transition: border-color 0.5s ease;
}
.customBox::before{
  position: absolute;
  width: 100%;
  height: 100%;
  color: #232323;
  font-size: 12px;
  font-family: Arial;
  content: 'Custom';
  left: 50%;
  top: 50%;
  line-height: 43px;
  transform: translate(-50%,-50%);
  display: block;
}

.hideBefore.customBox::before{
  display: none;
}
.customInput {
  outline: none;
  border: none;
  width: 70px;
  height: 100%;
  background: #EEEEEE;
  padding: 0 0 0 18px;
  border-radius: 8px;
}

.customInput:hover{
  background: #e7e7e7;
}



.customspan {
  font-family: Arial;
  font-size: 10px;
  font-weight: 400;
  color: #A6A6A6;

}

.cameraRightBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 8px;
}

.exampleDiagram {
  flex-shrink: 0;
  width: 103px;
  height: 103px;
  border-radius: 8px;
  background-color: #ebebeb;
}

.exampleDiagram img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.isCustom{
  border-radius: 8px;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0,0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}

.isCustom p{
  margin: 0;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  line-height: 20px;
  height: 20px;
}
.inputValue{
  font-size: 22px;
  letter-spacing: 0em;
  text-align: left;
  min-width: 38px;
  display: inline-block;
}
.generateBtn {
  width: 100%;
  margin-top: 0px;
  height: 40px;
  background: #CDCDCD;
  border-radius: 20px;
  color: #fff;
  line-height: 40px;
  position: relative;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(74, 0, 224, 0.7);
  }

  70% {
    opacity: 0.5;
    box-shadow: 0 0 0 10px rgba(74, 0, 224, 0);
  }

  100% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(74, 0, 224, 0);
  }
}

.generateProgress {
  background: #4A00E0;
  height: 100%;
  border-radius: 20px;
  transition: width 0.3s linear;
}

.generateProgressGenerating {
  background: #4A00E0;
  height: 100%;
  border-radius: 20px;
  transition: width 0.3s linear;
  animation: pulse 2s infinite;
}


.generateBtn span {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}