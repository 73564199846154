.licenseBox {
	background-size: 100%;
	background-repeat: no-repeat;
	width: 74px;
	height: 24px;
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #FFFFFF;
	user-select: none;
	cursor: pointer;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	position: relative;
}

.licenseBox.largest{
	width: 104px;
	height: 32px;
}
.licenseBox.largest img{
	width: 20px;
	height: 20px;
	margin-left: 10px;
	margin-right: 8px;
}
.licenseBox.largest span{
	font-size: 14px;
}
.licenseBox img {
	width: 16px;
	height: 16px;
	margin-left: 5px;
	margin-right: 2px;
}

.hoverWrapper {
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translateY(100%);
}

.hoverBox {
	width: 324px;
	background-color: #fff;
	height: 165px;
	box-shadow: 0px 3px 10px 0px rgba(75, 2, 224, 0.2);
	border-radius: 8px 8px 8px 8px;
	opacity: 1;
	border: 1px solid #7740E8;
	padding: 21px 18px 16px;
}

.licenseTitle {
	font-weight: 400;
	color: #B0B0B0;
	font-family: Arial-Regular, Arial;
}

.licenseTip {
	margin-right: 10px;
	font-size: 16px;
	color: #2D2D2D;
	font-family: Arial-Bold, Arial;
	font-weight: bold;
}

.price {
	font-family: Arial-Bold, Arial;
	font-weight: bold;
	font-size: 12px;
	color: #EA5C50;
}

.hoverBox p {
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #696969;
	text-align: left;
	margin-top: 8px;
}

.licenseButton {
	width: 288px;
	height: 38px;
	background: linear-gradient(270deg, #4A00E0 0%, rgba(74, 0, 224, 0.7) 100%);
	border-radius: 6px 6px 6px 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.licenseButton:hover{
	background: linear-gradient(270deg, rgba(74,0,224,0.85) 0%, rgba(74,0,224,0.6) 100%);
	box-shadow: 0px 1px 4px 0px rgba(74,1,224,0.3);
}
.licenseCanler {
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #C3C3C3;
	margin-bottom: 4px;
}