
.historyhide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear, visibility 0.3s;
}

.historyshow {
  opacity: 1;
  visibility: visible;  
  transition: opacity 0.3s linear, visibility 0.3s;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.popupContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: var(--loadingBoxBg);
  border-radius: 16px;
  padding: 24px 62px 16px;
  min-width: 40vw;
}
.popupHeader {
  position: relative;
  vertical-align: top;
}
.popupHeader h2 {
  position: relative;
  font-size: 16px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  height: 18px;
  
}

.popupHeader span {
  position: absolute;
  right: 0;
  top: 0px;
}

.filter {
  display: inline-block;
  width: 56px;
  height: 24px;
  background: var(--noResultBg);
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  position: absolute;
  top: 0;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  line-height: 24px;
}

.filter::after {
  position: absolute;
  content: '';
  display: block;
  width: 7px;
  height: 4.3px;
  margin-right: 5px;
  margin-top: 1px;
  background: url('../../../assets/arrow.svg');
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-size: 100%;

}

.filterBox {
  position: absolute;
  right: 0;
  top: calc(100%+5px);
  z-index: 1001;
  background: var(--noResultBg);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  visibility: hidden;
  opacity: 0;
  width: 56px;
  transition: opacity 0.3s linear, visibility 0.3s;
}

.filterBox.show {
  visibility: visible;
  opacity: 1;
}

.filterItem {
  height: 26px;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #767676;
  line-height: 26px;
}

.filterItem:hover {
  color: #fff;
}

.boder {
  width: 100%;
  height: 1px;
  background-color: var(--noResultBg);
  margin: 12px 0;
}
.popupBody{
  min-height: 384px;
}
.popupTags {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  color: var(--noResultText);
  margin-bottom: 8px;
}

.popupTags div {
  width: 25%;
}


.popupLine {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 0;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: var(--noResultText);
}

.popupLine div,.popupLine a {
  width: 25%;
}


.historyTaget {
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  line-height: 14px;
  background: linear-gradient(180deg, #FB28FF 0%, #8548ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.historyTaget:hover {
  background: linear-gradient(180deg, #FD85FF 0%, #8548FF 100%);
   -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.historySource {
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: var(--noResultText);
}