.shareBox {
  display: flex;
  z-index: 10000;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.canvasBox {
  position: relative;
  width: 390px;
  z-index: 10000;
  height: 288px;
}
.loadingOverlay {
  position: absolute;
  height: 390px;
  background: #fff;
  opacity: 0.8;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  font-style: 50px;
  font-weight: bold;
  color: #333;
  z-index: 100000;
}
.downloadImgBtn {
  background-color: rgb(73, 0, 211);
  color: white;
  border: none;
  border-radius: 18px;
  padding: 8px 16px;
  font-size: 18px;
  height: 40px;
  cursor: pointer;
  margin-top: 60px;
  text-align: center;
}

.qrBox {
  position: absolute;
  width: 460px;
  height: 390px;
  backdrop-filter: blur(5px);
  background: #fff;
  opacity: 0.9;
  z-index: 100000;
}