.imag3dWrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: calc(50vh - 16rem);
  transition: opacity 0.3s ease-in-out;
  margin-top: 80px;
  user-select: none;
}

.img3dBg {
  position: absolute;
  top: calc(50vh - 30rem);
  left: 20vw;
  width: 50vw;
  z-index: -1;
  /* background: #fff; */
}

.title {
  font-family: 'Courier New';
  font-size: 80px;
  font-weight: bold;
  line-height: 91px;
  color: #090830;
  position: relative;
}

.title:hover {
  transform: translateY(-5px);
  transition: color 0.3s, transform 0.3s;
}

.titleBox {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  position: relative;
}

.beforeBox {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  transform: translate(-110%, 30%);
}

.img3dBeforeCircle {
  width: 118px;
  height: 75px;
  transition: all 0.3s linear;
}

.imgto3dPhoto {
  transition: all 0.5s linear;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-40%, 90%);
  width: 87px;
  height: 130px;
  clip-path: circle(43px at center);
  z-index: -1;
}

.afterBox {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(350%, 90%);
}

.renderAfterimg {
  position: absolute;
  display: block;
  right: 0;
  top: -10px;
  width: 99px;
  height: 99px;
  transform: translate(30%, -90%);
  background-color: #2D2D2D;
  clip-path: circle(45px at center);
  transition: all 0.4s linear; 
}
.renderBeforeimg{
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  width: 62px;
  height: 62px;
  transform: translate(-110%, 60%);
  background-color: #2D2D2D;
  clip-path: circle(31px at center);
  transition: all 0.4s linear; 
}
.showBeforeBox,.showAfterBox,.showAfterCircle,.showBeforeCircle,.showImgto3dPhoto{
  opacity: 1;
  visibility: visible;
}
.hideBeforeBox,.hideAfterBox,.hideAfterCircle,.hideBeforeCircle,.hideImgto3dPhoto{
 opacity: 0;
  visibility: hidden;
}
.renderBeforeimg img,.renderAfterimg img{
  width: 100%;
  clip-path: circle(50%);
}
.img3dAfterCircle {
  transition: all 0.6s linear; 
  width: 55px;
  height: 88px;
}

.transitioning {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.fileDropBox{
  height: 100%;
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleMsg {
  margin-top: 10px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: rgba(9, 8, 48, 0.8);
}

.descriptBg {
  position: relative;
}

.descriptBg::after {
  position: absolute;
  display: block;
  content: '';
  width: 76px;
  height: 15px;
  right: -5px;
  bottom: -5px;
  background: #9CB8FF;
  mix-blend-mode: multiply;
}
.thumbnailContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px 0;
  width: 100%;
  height: 100%;
}
.thumbnailTips{
  font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #2D2D2D;
margin-bottom: 16px;
}
.thumbnailTips span{
  color: #0F52FF;
}
.imgWrapper{
  display: flex;
}
.thumbnailItem{
  width: 185px;
  height: 121px;
  position: relative;
}
.thumbnailItem img{
  width: auto;
  height: 100%;
  object-fit: contain;
}
.imgTodo{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 11px 5px 8px;
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, rgba(26, 26, 26, 0.3) 100%);
}
.imgTodoTitle{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  transform: scale(0.5);
  transform-origin: left center;
  color: #FFFFFF;
}
.imgDelete {
  position: absolute;
  left: 160px;
  top: 7px;
}
.imgDelete img{
  height: 16px;
  width: 16px;
}
.uploadBtn{
  margin-top: 16px;
  width: 87px;
  height: 34px;
  background: #6214FF;
  border-radius: 8px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  text-align: center;
  line-height: 34px;
}
.uploadBtn:hover{
  background: #4C02E1;
}

.deleteAll{
  position: absolute;
  right: 30px;
  top: 15px;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #2D2D2D;
  cursor: pointer;
}
.deleteAll:hover{
  color: #333;
}
.deleteAll::before{
  position: absolute;
  content: '';
  background-image: url('../../assets/deleteAll.png');
  background-size: 100%;
  left: 0;
  top: 0;
  transform: translate(calc(-100% - 5px));
  width: 16px;
  height: 16px;
}
.deleteAll:hover::before{
  background-image: url('../../assets/deletHover.png');
}
.uploadWrapper {
  margin-top: 50px;
  position: relative;
  width: calc(100% - 10rem);
	max-width: calc(1600px + 17rem);
  height: 244px;
  z-index: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.uploadWrapper::before {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  content: '';
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(-45deg, #C3C3C3 0, #C3C3C3 0.5em, white 0, white 0.75em);
  left: 50%;
  top: 50%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.6;
}

.uploadWrapper:hover::before ,.uploadHover.uploadWrapper::before{
  background: linear-gradient(#ebebeb, #ebebeb) padding-box,
    repeating-linear-gradient(-45deg, #C3C3C3 0, #C3C3C3 0.5em, white 0, white 0.75em);
}
.uploadIcon {
  cursor: pointer;
  color: #9CB8FF;
  /* position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%); */
  font-size: 4rem;
  z-index: 5
}

.tipText {
  font-size: 16px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #2D2D2D;
}


.placehoderText {
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #D1D1D1;
  position: relative;
  margin-top: 5px;
  cursor: pointer;
}
.placehoderText span{
  position: relative;
}
.placehoderText .textspan::before {
  content: '*';
  position: absolute;
  left: -8px;
  top: 0;
  color: rgba(255, 0, 0, 0.5);
}

.spanhover{
  color: #Afafaf;
}
.spanhover .hoverText{
  color: #3E3E3E;
  font-weight: 400;
}
.textspan span{
  color: #A4A4A4;
  font-weight: 700;
}

.placehoderText .spanhover::before{
   color: #FF0000;
 }
 .placeHodler{
  height: 10px;
 }
.previewWrapper {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%,100%);
  height: 85px;
  width: 328px;
  padding: 6px 8px;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(199, 199, 199, 0.25);
  border-radius: 8px;
}
.previewBox{
  background-color: #D9D9D9;
  height: 73px;
  width: 100px;
  overflow: hidden;
  /* margin-right: 6px; */
}
.previewBox img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.progressText{
  font-size: 16px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #2D2D2D;
  width: 58%;
  text-align: left;
}
.ProgressBar {
  margin-top: 10px;
  width: 60%;
  height: 24px;
  border-radius: 32px;
  background-color: #D9D9D9;
  padding: 0 4px;
  display: flex;
  align-items: center;
  /* border: 3px solid #999999; */
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.filler {
  height: 16px;
  background: linear-gradient(270deg, #4A00E0 0%, #9B71F0 100%);
  background-size: 200% 200%;
  border-radius: 32px;
  transition: width 0.5s ease-in-out;
  animation: gradient 2s ease infinite;
}
@keyframes progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}