.overlayBox{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
 width: 100px;
 height: 100px;
 position: relative;
}

.spinner::after,.spinner::before {
 box-sizing: border-box;
 position: absolute;
 content: "";
 width: 30px;
 height: 30px;
 top: 50%;
 animation: up 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
 left: 50%;
 background: #628eff;
}

.spinner::after {
 background: #b996ff;
 top: calc(50% - 30px);
 left: calc(50% - 30px);
 animation: down 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes down {
 0%, 100% {
  transform: none;
 }

 25% {
  transform: translateX(100%);
 }

 50% {
  transform: translateX(100%) translateY(100%);
 }

 75% {
  transform: translateY(100%);
 }
}

@keyframes up {
 0%, 100% {
  transform: none;
 }

 25% {
  transform: translateX(-100%);
 }

 50% {
  transform: translateX(-100%) translateY(-100%);
 }

 75% {
  transform: translateY(-100%);
 }
}





.loader {
  --path: #628eff;
  --dot: #b996ff;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  /* transform: translate(-18px, -18px); */
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect{
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}


.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 320;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}


@keyframes pathRect {
  25% {
    stroke-dashoffset: 256;
  }

  50% {
    stroke-dashoffset: 192;
  }

  75% {
    stroke-dashoffset:128;
  }

  100% {
    stroke-dashoffset:64 ;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(-18px, -18px);
  }

  50% {
    transform: translate(0, -36px);
  }

  75% {
    transform: translate(18px, -18px);
  }

  100%{
    transform: translate(0, 0);
  }
}



.loader {
  display: inline-block;
  margin: 0 16px;
  transform: scale(0.5);
}

 

 .skeleton {
  border-radius: 8px;
  width: 100%;
  height: 100%;
	overflow: hidden;
	cursor: pointer;
	background:var(--cardSkeletonBg) ;
	background-size: 200% 100%;
	animation: loading 1.5s infinite;
}

@keyframes loading {
	0% {
		background-position: 200% 0;
	}

	100% {
		background-position: -200% 0;
	}
}
